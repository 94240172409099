import { Fragment } from "react";
// #region packages
import { Link } from "react-router-dom";
import { arrowForward } from "ionicons/icons";
import {
   IonButton,
   IonCard,
   IonFabButton,
   IonIcon,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonSkeletonText,
} from "@ionic/react";
// #endregion
// #region components
import OverviewItem from "./OverviewItem";
// #endregion
// #region styles
import "@ionic/react/css/core.css";
// #endregion
// #region interfaces
import { User } from "../interfaces/User";
// #endregion
// #region utilities
import { currency, printDate } from "../utilities/Formatters";
// #endregion

interface OverviewCardProps {
   user?: User | null;
}

const OverviewCard = (props: OverviewCardProps) => {
   const user = props.user!;
   return (
      <IonCard >
         <IonList className="ion-no-padding">
            <IonListHeader className="white" >
               <IonLabel>
                  <h2>Account Overview</h2>
               </IonLabel>
               <Link to="/AccountOverview" >
                  <IonFabButton className="fab-button" color="light">
                     <IonIcon className="arrow-color" icon={arrowForward} />
                  </IonFabButton>
               </Link>
            </IonListHeader>
            <OverviewItem name="Estimated Payoff Date" iconImage="/images/goal.svg" value={
               user?.PayoffDate
                  ? (<Fragment>{printDate(user.PayoffDate as Date)}</Fragment>)
                  : (<IonSkeletonText animated style={{ width: "60%" }} />)
            } />
            <OverviewItem name="Upcoming Due Date" iconImage="/images/calendar.svg" value={
               user?.NextPaymentDueOn
                  ? (<Fragment>{printDate(user.NextPaymentDueOn)}</Fragment>)
                  : (<IonSkeletonText animated style={{ width: "60%" }} />)
            } />
            {user?.NextAutopayDate && (
               <OverviewItem name="Next Autopay Pull" iconImage="/images/next-pull.svg" value={
                  user?.NextAutopayDate
                     ? (<Fragment>{printDate(user.NextAutopayDate!)}</Fragment>)
                     : (<IonSkeletonText animated style={{ width: "60%" }} />)
               } />
            )}
            <OverviewItem name="Amount Due" iconImage="/images/notes.svg" value={
               user?.CurrentMonthlyPayment
                  ? (<Fragment>{currency(user.CurrentMonthlyPayment)}</Fragment>)
                  : (<IonSkeletonText animated style={{ width: "60%" }} />)
            } />
            <OverviewItem name="Client Trust Balance" iconImage="/images/trust-balance.svg" value={
               (<Fragment>{user?.TrustBalance
                  ? currency(user.TrustBalance)
                  : "$0.00"}</Fragment>)
            } />
            <IonItem className="full-button" lines="none" style={{ marginTop: "0", marginBottom: (user?.HasAutopay ? "0" : "8px") }}>
               <IonButton href="/MakePayment" className="full-button" expand="full" data-cy="make-payment">
                  Make Payment
               </IonButton>
            </IonItem>
            {user?.HasAutopay && (
               <IonItem className="full-button" lines="none" style={{ marginTop: "0" }}>
                  <IonButton href="/ChangeAutopay" className="full-button" expand="full" data-cy="change-autopay">
                     Change Autopay Date
                  </IonButton>
               </IonItem>
            )}
         </IonList>
      </IonCard>
   );
};

export default OverviewCard;
