import { Fragment } from "react";
// #region packages
import { FieldValues, useForm } from "react-hook-form";
import {
   IonButton,
   IonCard,
   IonCol,
   IonContent,
   IonGrid,
   IonInput,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonPage,
   IonRow,
   IonSpinner,
   IonTextarea,
   useIonRouter,
} from "@ionic/react";
import axios from "axios";
// #endregion
// #region components
import ErrorBadge from "../components/ErrorBadge";
import Header from "../components/Header";
import Menu from "../components/Menu";
import AppFooter from "../components/AppFooter";
import Toaster, { popError, popSuccess } from "../components/Toaster";
import WelcomeBanner from "../components/WelcomeBanner";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region interfaces
import { GenericResponse } from "../interfaces/GenericResponse";
import { User } from "../interfaces/User";
// #endregion
// #region utilities
import { CONTACT_US_EMAILS, StdErrorMsg } from "../utilities/Constants";
import { debugLog } from "../utilities/debugLog";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
// #region Azure B2C
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-common";
import { protectedResources } from "../authConfig";
// #endregion

interface ContactProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const Contact: React.FC<ContactProps> = (props) => {
   const user = props.user;

   useIris();
   useFeedbackButton();

   const history = useIonRouter();
   const { instance } = useMsal();

   const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
      mode: "onBlur",
      reValidateMode: "onChange"
   });

   const newLinesToBrTag = (str: string) => {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
   };

   const sendEmailCall = async (accessToken: string, data: FieldValues) => {
      if (data.subject.length > 0 && data.message.length > 0) {
         const mailbody = `${newLinesToBrTag(data.message)}'<br/><br/>'` +
            `Email : ${user!.Email ?? ""}<br/>` +
            `First Name : ${user!.FirstName ?? ""}<br/>` +
            `Last Name : ${user!.LastName ?? ""}`;

         try {
            const url = protectedResources.sendEmail.endpoint;

            debugLog("*** Attempting to contact " + url);
            const smtpResponse = await axios.post<GenericResponse>(
               url, {
               recipients: CONTACT_US_EMAILS,
               subject: data.subject,
               body: mailbody,
               cache: false
            }, {
               headers: {
                  Authorization: `Bearer ${accessToken}`
               }
            });

            if (smtpResponse.data.IsSuccess) {
               popSuccess("Message sent", { onClose: () => history.push("/Overview") });
            } else {
               popError(StdErrorMsg("Error sending message."));
            }
         } catch (error) {
            if ((error as any).code === "ERR_NETWORK") {
               popError("Error contacting server. Please check your network connection");
            } else {
               popError(StdErrorMsg("Error sending message."));
            }
         }
      }
   };

   const sendEmail = async (data: FieldValues) => {
      if (data.subject.length > 0 && data.message.length > 0) {

         try {
            const account = instance.getActiveAccount() as AccountInfo | undefined;
            const accessTokenRequest = {
               scopes: protectedResources.sendEmail.scopes,
               account: account
            };

            const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            const accessToken = accessTokenResponse.accessToken;
            await sendEmailCall(accessToken, data);
         } catch (error) {
            debugLog((error as any).toString());
            popError(StdErrorMsg("Error sending message."));
         }

      }
   };

   return (
      <Fragment>
         <Menu contentId="contact" />
         <IonPage>
            <Header title="Contact" />
            <IonContent id="contact">
               <IonGrid>

                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <WelcomeBanner />
                     </IonCol>
                  </IonRow>

                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <IonCard>

                           <form onSubmit={handleSubmit(sendEmail)}>
                              <IonList className="ion-no-padding">

                                 <IonListHeader className="white ion-padding-end">
                                    <IonLabel>
                                       <h2>Contact Us</h2>
                                    </IonLabel>
                                 </IonListHeader>

                                 <IonItem lines="none">
                                    <IonLabel className="required" position="stacked">Subject</IonLabel>
                                    <IonInput
                                       placeholder="Subject"
                                       {...register("subject", { required: true })} />
                                    {errors.subject &&
                                       <ErrorBadge>Subject is required</ErrorBadge>}
                                 </IonItem>

                                 <IonItem lines="none">
                                    <IonLabel className="required" position="stacked">Message</IonLabel>
                                    <IonTextarea
                                       placeholder="Message"
                                       spellCheck={true}
                                       autoGrow={false}
                                       rows={5}
                                       {...register("message", { required: true })} />
                                    {errors.message &&
                                       <ErrorBadge>Message is required</ErrorBadge>}
                                 </IonItem>

                              </IonList>

                              <IonItem>
                                 <IonButton
                                    className="full-button"
                                    expand="full"
                                    disabled={isSubmitting}
                                    type="submit">
                                    {
                                       isSubmitting &&
                                       (
                                          <Fragment>
                                             Sending <IonSpinner style={{ marginLeft: "12px" }} />
                                          </Fragment>
                                       )
                                    }
                                    {
                                       !isSubmitting &&
                                       (
                                          <span>Send</span>
                                       )
                                    }
                                 </IonButton>
                              </IonItem>

                           </form>

                        </IonCard>

                     </IonCol>
                  </IonRow>

               </IonGrid>
               <p className="withIris">&nbsp;</p>
            </IonContent>
            <Toaster />
            <AppIdleTimer logout={props.logout} />
            <AppFooter />
         </IonPage>
      </Fragment>
   );
};

export default Contact;
