import { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { IonButton, IonHeader, IonItem, IonTitle, IonToolbar, IonModal } from "@ionic/react";
import { debugLog } from "../utilities/debugLog";

interface AppIdleTimerProps {
   timeoutInSeconds?: number;
   promptTimeInSeconds?: number;
   idleCallback?: () => void;
   logout: () => void;
}

const AppIdleTimer: React.FC<AppIdleTimerProps> = (props: AppIdleTimerProps) => {
   const timeoutInSeconds = props.timeoutInSeconds || 60 * 16; // 16 minutes. allows 15 minutes idle time with 1 minute buffer for prompt
   const promptTimeInSeconds = props.promptTimeInSeconds || 60; // 1 minute

   // Modal open state
   const [isOpen, setIsOpen] = useState(false);

   const onIdle = () => {
      // onIdle will be called after the timeout is reached.
      // Close the prompt and log the user out
      setIsOpen(false);
      debugLog(`Time remaining: ${getRemainingTime()} seconds`);

      if (props.idleCallback) {
         props.idleCallback();
      } else {
         props.logout();
      }
   };

   const onActive = () => {
      // Docs say: onActive will only be called if `activate()` is called while `isPrompted()`
      // is true. Here we will also want to close the modal and perform
      // any active actions.
      // But it seems to be called way more often than that. So we'll deal with it the way they should have.
      if (isPrompted()) {
         setIsOpen(false);
         reset();
      }

      start();
      debugLog(`Time remaining: ${Math.ceil(getRemainingTime() / 1000)} seconds`);
   };

   const onPrompt = () => {

      // onPrompt will be called `promptTimeInSeconds` seconds before `timeoutInSeconds`.
      // Here we open the prompt.
      // All events are disabled while the prompt is active.
      if (!isPrompted()) {
         setIsOpen(true);
      }  

      debugLog(`Time remaining: ${Math.ceil(getRemainingTime() / 1000)} seconds`);
   };

   const { getRemainingTime, isPrompted, activate, start, reset } = useIdleTimer({
      onPrompt: onPrompt,
      onIdle: onIdle,
      onActive: onActive,
      timeout: timeoutInSeconds * 1000,
      promptBeforeIdle: promptTimeInSeconds * 1000,
      startOnMount: false,
      startManually: true,
      stopOnIdle: false,
      debounce: 150,
      crossTab: true,
      syncTimers: 500,
      events: [
         "keydown",
         "wheel",
         "DOMMouseScroll",
         "mousewheel",
         "mousedown",
         "touchstart",
         "touchmove",
         "MSPointerDown",
         "visibilitychange"
      ]
   });

   const handleStillHere = () => {
      activate();
   };

   useEffect(() => {
      const interval = setInterval(() => {
         debugLog(`Time remaining: ${Math.ceil(getRemainingTime() / 1000)} seconds`);
      }, 1000);

      return () => {
         clearInterval(interval);
      };
   }, [getRemainingTime]);

   useEffect(() => {
      activate();
   }, [activate]);

   return (
      <IonModal isOpen={isOpen} keepContentsMounted={true} backdropDismiss={false}>
         <IonHeader>
            <IonToolbar>
               <IonTitle>Are you still there?</IonTitle>
            </IonToolbar>
         </IonHeader>
         <IonItem lines="none" style={{ lineHeight: 1.8 }} >
            We haven't seen any activity for quite a while.
            For security reasons, we'll log you out in {Math.ceil(getRemainingTime() / 1000)} seconds.
            Or you can click the button below to let us know you're still here.
         </IonItem>
         <IonItem className="full-button">
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
               <IonButton onClick={handleStillHere}>I'm still here</IonButton>
            </div>
         </IonItem>
      </IonModal >
   );
};


export default AppIdleTimer;
