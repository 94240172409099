import { Fragment } from "react";
// #region packages
import {
   IonCard,
   IonCol,
   IonContent,
   IonGrid,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonPage,
   IonRow
} from "@ionic/react";
// #endregion
// #region components
import AppFooter from "../components/AppFooter";
import DebtGraph from "../components/DebtGraph";
import ExpandableList from "../components/ExpandableList";
import ListDisplayItem from "../components/ListDisplayItem";
import SubpageHeader from "../components/SubpageHeader";
// #endregion
// #region interfaces
import { CaseDebt, CaseDeposit, CaseFee, User } from "../interfaces/User";
// #endregion
// #region utilities
import { date, currency } from "../utilities/Formatters";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
interface AccountOverviewProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const AccountOverview: React.FC<AccountOverviewProps> = (props) => {
   const user = props.user;

   useIris();
   useFeedbackButton();

   const debts = user?.CaseDebts ?? ([] as CaseDebt[]);
   const deposits = user?.CaseDeposits ?? ([] as CaseDeposit[]);
   const fees = user?.CaseFees ?? ([] as CaseFee[]);

   const getDepositItem = (deposit: CaseDeposit, index?: number) => {
      return (
         <IonItem key={index}>
            <IonLabel>
               <h3>{date(new Date(deposit.PostedDate!))}</h3>
            </IonLabel>
            <div className="ion-text-right row-text">
               {currency(deposit.Amount!)}
            </div>
         </IonItem>
      );
   };

   const getFeeItem = (fee: CaseFee, index?: number) => {
      return (
         <IonItem key={index}>
            <IonLabel>
               <h3>{fee.Title}</h3>
            </IonLabel>
            <div className="ion-text-right row-text">
               {currency(fee.PaidToDate)}
            </div>
         </IonItem>
      );
   };


   const getDebtItem = (debt: CaseDebt, index?: number) => {
      return (
         <IonItem key={index}>
            <IonLabel>
               <h3>{debt.CreditorName}</h3>
            </IonLabel>
            <div className="ion-text-right row-text">
               {currency(debt.CurrentBalance!)}
            </div>
         </IonItem>
      );
   };

   return (
      <Fragment>
         {user ? (
            <IonPage>
               <SubpageHeader title="Account Overview" parentPage="/Overview" />

               <IonContent id="accountOverview">
                  <IonGrid>
                     <IonRow>

                        <IonCol size="12" sizeLg="8" offsetLg="2">
                           <div className="overview-grid" style={{ display: "grid" }}>
                              <div className="left-column">
                                 <IonCard style={{ gridColumn: 1, gridRow: 1 }}>
                                    <IonList className="ion-no-padding">
                                       <IonListHeader className="white ion-text-center ion-padding-end">
                                          <IonLabel>
                                             <h2>Account Details</h2>
                                          </IonLabel>
                                       </IonListHeader>
                                       <ListDisplayItem name="Current Balance" value={currency(user!.EstimatedBalance!)!} />
                                       <ListDisplayItem name="Monthly Payment" value={currency(user!.CurrentMonthlyPayment!)!} />
                                    </IonList>
                                 </IonCard>

                                 <IonCard style={{ gridColumn: 1, gridRow: 2 }}>
                                    <ExpandableList
                                       data={deposits}
                                       onItemDisplay={getDepositItem}
                                       visibleRows={10}
                                       collapsible={true}
                                       title="Deposit History" />
                                 </IonCard>
                              </div>

                              <div className="right-column">
                                 <IonCard>
                                    <ExpandableList
                                       data={debts}
                                       scroll={false}
                                       collapsible={debts?.length > 5}
                                       onItemDisplay={getDebtItem}
                                       title="Balance Breakdown"
                                    />
                                 </IonCard>

                                 <IonCard>
                                    <IonItem>
                                       <div className="chart-div ion-padding-vertical">
                                          <DebtGraph items={debts} />
                                       </div>
                                    </IonItem>
                                 </IonCard>
                              </div>

                              <IonCard>
                                 <ExpandableList
                                    data={fees}
                                    onItemDisplay={getFeeItem}
                                    detailLink={fees?.length > 0 ? "/Fees" : undefined}
                                    scroll={false}
                                    collapsible={fees?.length > 5}
                                    title="DMP Fees Paid" />
                              </IonCard>
                           </div>
                        </IonCol>
                     </IonRow>
                  </IonGrid>
                  <p className="withIris">&nbsp;</p>
               </IonContent>
               <AppFooter />
               <AppIdleTimer logout={props.logout} />
            </IonPage>
         ) : (
            <Fragment />
         )}
      </Fragment>
   );
};

export default AccountOverview;
