import { Fragment } from "react";

// #region packages
import {
   IonCard,
   IonCardContent,
   IonCol,
   IonContent,
   IonGrid,
   IonPage,
   IonRow
} from "@ionic/react";
// #endregion
// #region components
import AppFooter from "../components/AppFooter";
import AppIdleTimer from "../components/AppIdleTimer";
import Header from "../components/Header";
import LenderList from "../components/LenderList";
import Menu from "../components/Menu";
import OpenPage from "../components/OpenPage";
import OverviewCard from "../components/OverviewCard";
import ProgressTrackerCard from "../components/ProgressTrackerCard";
import Toaster from "../components/Toaster";
import WelcomeBanner from "../components/WelcomeBanner";
// #endregion
// #region interfaces
import { User, CaseDebt } from "../interfaces/User";
// #endregion
// #region utilities
import { debugLog } from "../utilities/debugLog";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
interface OverviewProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const Overview: React.FC<OverviewProps> = (props) => {
   const user = props.user;

   useIris();
   useFeedbackButton();

   // hack to work around a very strange error if this text were emitted as-is
   const dfiUrl = "http://www.in.gov/dfi";

   debugLog("Overview.tsx");
   debugLog(`user: ${user}`);

   return (
      <Fragment>
         <Menu contentId="overview" />
         <IonPage>
            <Header />
            <IonContent id="overview">
               <IonGrid className="lender-grid">

                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <WelcomeBanner />
                     </IonCol>
                  </IonRow>

                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <ProgressTrackerCard user={user} />
                     </IonCol>
                  </IonRow>

                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <IonRow>

                           <IonCol size="12" sizeLg="6">
                              <OverviewCard user={user} />
                           </IonCol>

                           <IonCol size="12" sizeLg="6">
                              <LenderList debts={user?.CaseDebts ?? ([] as CaseDebt[])} />
                           </IonCol>

                        </IonRow>
                     </IonCol>
                  </IonRow>

                  <IonRow >
                     <IonCol size="12" sizeLg="8" offsetLg="2" className="disclosure">
                        <IonCard>
                           <IonCardContent>
                              *Indiana Residents: Plan is regulated by the Indiana Department of Financial Institutions, 30 S Meridian St., Suite 300, Indianapolis, IN, 46204,
                              <OpenPage url="tel:800-382-4880">(800) 382-4880</OpenPage>,
                              <OpenPage url="http://www.in.gov/dfi">{dfiUrl}</OpenPage>
                              <br />
                              *New York Residents: Questions or complaints about Apprisen may be directed to the New York State Department of Financial Services at <OpenPage url="tel:800-342-3736">(800) 342-3736</OpenPage>
                           </IonCardContent>
                        </IonCard>
                     </IonCol>
                  </IonRow>

               </IonGrid>
               <p className="withIris">&nbsp;</p>
            </IonContent>
            <Toaster />
            <AppFooter />
            <AppIdleTimer logout={props.logout} />
         </IonPage>

      </Fragment>
   );
};

export default Overview;
