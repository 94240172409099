import { useEffect } from "react";

declare module "react" {
   interface ScriptHTMLAttributes<T> extends React.HTMLAttributes<T> {
      appKey?: string;
   }
}

const useIris = () => {
   useEffect(() => {
      const voidArea = document.createElement("div");
      voidArea.setAttribute("style", "min-height: 90px;");
      voidArea.innerHTML = "&nbsp;";
      document.body.appendChild(voidArea);

      document.body.classList.add("usesIris");

      const script = document.createElement("script");

      script.src = "https://chat.sharpen.cx";
      script.setAttribute("appKey", "7EA3183AFCFB902FB15A50758F223E9BC4EEC19BE8778974BCF0D6555789614263125BE335BCF21A");
      script.async = true;

      document.body.appendChild(script);
   }, []);
};

export default useIris;
