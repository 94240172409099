// #region packages
import { IonBadge } from "@ionic/react";
// #endregion

interface ErrorBadgeProps {
   children?: React.ReactNode;
   style?: {[key: string]: any}
}

const ErrorBadge: React.FC<ErrorBadgeProps> = (props: ErrorBadgeProps) => {
   return (<IonBadge style={{background: "transparent", color: "red", ...props.style}}>{props.children}</IonBadge>);
};

export default ErrorBadge;
