import { Fragment } from "react";
// #region packages
import {
   IonButton,
   IonCard,
   IonCol,
   IonContent,
   IonGrid,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonPage,
   IonRow,
   useIonRouter
} from "@ionic/react";
// #endregion
// #region components
import Header from "../components/Header";
import Menu from "../components/Menu";
import AppFooter from "../components/AppFooter";
import WelcomeBanner from "../components/WelcomeBanner";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region interfaces
import { User } from "../interfaces/User";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
import useQuery from "../hooks/useQuery";
// #endregion
interface PaymentConfirmationProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const PaymentConfirmation: React.FC<PaymentConfirmationProps> = (props) => {
   useIris();
   useFeedbackButton();

   const history = useIonRouter();

   const query = useQuery();

   return (
      <Fragment>
         <Menu contentId="paymentConfirmation" />
         <IonPage>
            <Header title="Make a Payment" />

            <IonContent id="paymentConfirmation">
               <IonGrid>
                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <WelcomeBanner />
                     </IonCol>
                  </IonRow>

                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <IonCard>
                           <IonList className="ion-no-padding">

                              <IonListHeader className="white ion-text-center ion-padding-end">
                                 <IonLabel>
                                    <h2>Confirmation Number</h2>
                                 </IonLabel>
                              </IonListHeader>

                              <IonItem lines="none">
                                 <IonLabel className="center-aligned">
                                    <h2>
                                       Payment successful! Your confirmation number is
                                    </h2>
                                 </IonLabel>
                              </IonItem>

                              <IonItem lines="none">
                                 <IonLabel className="bold-and-centered" >
                                    <h2 style={{ marginBottom: "0.6em" }}>{query.get("conf")}</h2>
                                 </IonLabel>
                              </IonItem>

                              <IonButton
                                 onClick={(e) => {
                                    e.preventDefault();
                                    history.push("/Overview");
                                 }}
                                 expand="block">
                                 Back to Overview
                              </IonButton>

                           </IonList>
                        </IonCard>
                     </IonCol>
                  </IonRow>
               </IonGrid>
               <p className="withIris">&nbsp;</p>
            </IonContent>
            <AppFooter />
            <AppIdleTimer logout={props.logout} />
         </IonPage>
      </Fragment>
   );
};

export default PaymentConfirmation;
