import { Fragment } from "react";
// #region packages
import {
   IonCard,
   IonCol,
   IonContent,
   IonGrid,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonPage,
   IonRow
} from "@ionic/react";
// #endregion
// #region components
import ExpandableList from "../components/ExpandableList";
import AppFooter from "../components/AppFooter";
import ListDisplayItem from "../components/ListDisplayItem";
import ProgressTrackerCard from "../components/ProgressTrackerCard";
import SubpageHeader from "../components/SubpageHeader";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region interfaces
import { CaseDebt, CaseDeposit, User } from "../interfaces/User";
// #endregion
// #region hooks
import useQuery from "../hooks/useQuery";
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
// #region utilities
import { currency, date } from "../utilities/Formatters";
// #endregion
interface LenderOverviewProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const LenderOverview: React.FC<LenderOverviewProps> = (props) => {
   const user = props.user;

   useIris();
   useFeedbackButton();

   const query = useQuery();

   const debtId = query.get("debt") || "";
   const debt = CaseDebt.Clone(((user?.CaseDebts ?? []) as CaseDebt[]).find((obj) => obj.DebtId === debtId)) as CaseDebt;
   const deposits = CaseDeposit.Clone(debt?.Deposits ?? []) as CaseDeposit[];

   if (!debt) {
      props.logout();
      return (<Fragment />);
   }

   const getDepositItem = (deposit: CaseDeposit, index?: number) => {
      return (
         <IonItem key={index}>
            <IonLabel>
               <h3>{date(new Date(deposit.PostedDate!))}</h3>
            </IonLabel>
            <div className="ion-text-right row-text">
               {currency(deposit.Amount!)}
            </div>
         </IonItem>
      );
   };

   return (
      <IonPage>
         <SubpageHeader title={debt.CreditorName} parentPage="/Overview" />
         <IonContent>
            <IonGrid>
               <IonRow>
                  <IonCol size="12" sizeLg="8" offsetLg="2">
                     <ProgressTrackerCard user={user} specificDebt={debt} />
                  </IonCol>
               </IonRow>
               <IonRow>
                  <IonCol size="12" sizeLg="8" offsetLg="2">
                     <IonRow>
                        <IonCol size="12" sizeLg="6">
                           <IonCard>
                              <IonList className="ion-no-padding">
                                 <IonListHeader className="white ion-text-center ion-padding-end">
                                    <IonLabel>
                                       <h2>Lender Details</h2>
                                    </IonLabel>
                                 </IonListHeader>
                                 <ListDisplayItem name="Account Number" value={debt.AccountNumber ?? ""} />
                                 <ListDisplayItem name="Remaining Balance" value={currency(debt.CurrentBalance ?? 0)!} />
                                 <ListDisplayItem name="Starting Balance" value={currency(debt.OriginalBalance ?? 0)!} />
                                 <ListDisplayItem name="Estimated Payoff Date" value={date(debt.PayoffDate)!} />
                                 <ListDisplayItem name="Proposal Status" value={debt.ProposalStatus ?? "Unknown"} />
                                 <ListDisplayItem name="APR" value={`${((debt.Apr ?? 0) * 100).toFixed(3)}%`} />
                                 <ListDisplayItem name="Payment Status" value={CaseDebt.DebtTypeDisplay(debt.DebtType!)} />
                              </IonList>
                           </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg="6">
                           <IonCard>
                              <ExpandableList
                                 data={deposits}
                                 visibleRows={7}
                                 onItemDisplay={getDepositItem}
                                 title="Payment History" />
                           </IonCard>

                        </IonCol>
                     </IonRow>
                  </IonCol>
               </IonRow>
            </IonGrid>
            <p className="withIris">&nbsp;</p>
         </IonContent>
         <AppFooter />
         <AppIdleTimer logout={props.logout} />
      </IonPage>
   );
};


export default LenderOverview;
