// #region packages
import {
   IonButtons,
   IonContent,
   IonHeader,
   IonIcon,
   IonItem,
   IonLabel,
   IonList,
   IonMenu,
   IonMenuButton,
   IonMenuToggle,
   IonTitle,
   IonToolbar,
   useIonRouter,
} from "@ionic/react";

import { menuController } from "@ionic/core/components";
import { helpCircleOutline, home, person, mailOutline, trailSignOutline, exitOutline } from "ionicons/icons";

// #endregion
// #region styles
import "./Menu.css";
// #endregion

interface AppPage {
   url: string;
   component: string;
   mdIcon: string;
   title: string;
}

const appPages: AppPage[] = [
   {
      title: "Overview",
      url: "/Overview",
      component: "Overview",
      mdIcon: home
   },
   {
      title: "Profile",
      url: "/Profile",
      component: "Profile",
      mdIcon: person
   },
   {
      title: "Contact Us",
      url: "/Contact",
      component: "Contact",
      mdIcon: mailOutline
   },
   {
      title: "Additional Resources",
      url: "/AdditionalResources",
      component: "AdditionalResources",
      mdIcon: trailSignOutline
   },
   {
      title: "About Us",
      url: "/About",
      component: "About",
      mdIcon: helpCircleOutline
   },
   {
      title: "Logout",
      url: "/Logout",
      component: "Logout",
      mdIcon: exitOutline
   }
];

interface MenuProps {
   contentId: string;
}

const Menu = ( props: MenuProps ) => {
   const history = useIonRouter();

   return (
      <IonMenu side="end" menuId="menu" type="overlay" contentId={ props.contentId }>

         <IonHeader className="toolbar-header">
            <IonToolbar className="toolbar-header">
               <IonTitle>Menu</IonTitle>
               <IonButtons slot="end">
                  <IonMenuToggle autoHide={ true }>
                     <IonMenuButton menu="menu" />
                  </IonMenuToggle>
               </IonButtons>
            </IonToolbar>
         </IonHeader>

         <IonContent style={ { paddingTop: "0px" } }>
            <IonList style={ { paddingTop: "0px" } }>
               { appPages.map( ( appPage, index ) => (
                  <IonItem lines="none" key={ index }>
                     <IonLabel
                        style={ { cursor: "pointer" } }
                        onClick={ (e) => {
                           e.preventDefault();
                           menuController.close().then(() => {history.push(appPage.url);});
                        } }>
                        <IonIcon icon={ appPage.mdIcon } /> { appPage.title }
                     </IonLabel>
                  </IonItem>
               ) ) }
            </IonList>
         </IonContent>
      </IonMenu> );
};

export default Menu;

