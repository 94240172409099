import axios from "axios";
import { IPublicClientApplication, AccountInfo, InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources, stdScopes, b2cPolicies } from "../authConfig";
import { Capacitor } from "@capacitor/core";
import { debugLog } from "../utilities/debugLog";

export class User {
   // #region properties
   BankAccountTypes: BankAccountType[] = [];
   CaseDebts?: CaseDebt[];
   CaseDeposits?: CaseDeposit[];
   CaseFeeDetails?: CaseFeeDetail[];
   CaseFees?: CaseFee[];
   CellPhone?: string;
   City?: string;
   ClientId?: string;
   ClientNumber?: number;
   CurrentMonthlyPayment?: number;
   CurrentProgress?: number;
   DmpCaseId?: bigint;
   Email?: string;
   Errors?: [{ key: string, value: string; }];
   EstimatedBalance?: number;
   FirstName?: string;
   HasAutopay?: boolean;
   IsApproved?: boolean;
   IsSuccess?: boolean;
   LastName?: string;
   NextAutopayDate?: Date;
   NextPaymentDueOn?: Date;
   PayoffDate?: Date;
   State?: string;
   States: StateInfo[] = [];
   StatusCode?: number;
   StreetAddress?: string;
   TotalOriginalBalance?: number;
   TrustBalance?: number;
   UserId?: string;
   UserName?: string;
   IdentityId?: string;
   // #endregion

   static Clone(data: any): User {
      const _user = new User();

      _user.BankAccountTypes = BankAccountType.Clone(data.BankAccountTypes ?? data.bankAccountTypes) as BankAccountType[];
      _user.CaseDebts = CaseDebt.Clone(data.CaseDebts ?? data.caseDebts) as CaseDebt[];
      _user.CaseDeposits = CaseDeposit.Clone(data.CaseDeposits ?? data.caseDeposits) as CaseDeposit[];
      _user.CaseFeeDetails = CaseFeeDetail.Clone(data.CaseFeeDetails ?? data.caseFeeDetails) as CaseFeeDetail[];
      _user.CaseFees = CaseFee.Clone(data.CaseFees ?? data.caseFees) as CaseFee[];
      _user.CellPhone = data.CellPhone ?? data.cellPhone;
      _user.City = data.City ?? data.city;
      _user.ClientId = data.ClientId ?? data.clientId;
      _user.ClientNumber = data.ClientNumber ?? data.clientNumber;
      _user.CurrentMonthlyPayment = data.CurrentMonthlyPayment ?? data.currentMonthlyPayment;
      _user.CurrentProgress = data.CurrentProgress ?? data.currentProgress;
      _user.DmpCaseId = data.DmpCaseId ?? data.dmpCaseId;
      _user.Email = data.Email ?? data.email;
      _user.Errors = data.Errors ?? data.errors;
      _user.EstimatedBalance = data.EstimatedBalance ?? data.estimatedBalance;
      _user.FirstName = data.FirstName ?? data.firstName;
      _user.HasAutopay = data.HasAutopay ?? data.hasAutopay;
      _user.IsApproved = data.IsApproved ?? data.isApproved;
      _user.IsSuccess = data.IsSuccess ?? data.isSuccess;
      _user.LastName = data.LastName ?? data.lastName;
      _user.NextAutopayDate = data.NextAutopayDate ?? data.nextAutopayDate;
      _user.NextPaymentDueOn = data.NextPaymentDueOn ?? data.nextPaymentDueOn;
      _user.PayoffDate = data.PayoffDate ?? data.payoffDate;
      _user.State = data.State ?? data.state;
      _user.States = StateInfo.Clone(data.States ?? data.states) as StateInfo[];
      _user.StatusCode = data.StatusCode ?? data.statusCode;
      _user.StreetAddress = data.StreetAddress ?? data.streetAddress;
      _user.TotalOriginalBalance = data.TotalOriginalBalance ?? data.totalOriginalBalance;
      _user.TrustBalance = data.TrustBalance ?? data.trustBalance;
      _user.UserId = data.UserId ?? data.userId;
      _user.UserName = data.Username ?? data.username;
      _user.IdentityId = data.IdentityId ?? data.identityId;

      return _user;
   }

   static async Load(instance: IPublicClientApplication, account?: AccountInfo | undefined): Promise<User | null | undefined> {
      let result: User | null | undefined = null;
      //debugLog("User.Load()");

      const activeAccount = account ?? instance.getActiveAccount();
      if (!activeAccount) {
         result = null;
      } else {
         const clientNumber = (activeAccount.idTokenClaims!["extension_ClientNumber"] as string | null);
         const accessTokenRequest = {
            scopes: protectedResources.getClientData.scopes,
            account: activeAccount
         };

         try {
            const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            const accessToken = accessTokenResponse.accessToken;
            const url = `${protectedResources.getClientData.endpoint}/${clientNumber ?? "0"}`;
            debugLog(`protectedResources.getClientData.endpoint = ${protectedResources.getClientData.endpoint}`);
            debugLog(`*** Attempting to contact ${url}`);

            try {
               const config = {
                  headers: { Authorization: `Bearer ${accessToken}` },
                  cache: false
               };

               const getClientDataResponse = await axios.get<User>(url, config);

               debugLog(`*** GET for ${url} succeeded`);
               const _user = User.Clone(getClientDataResponse.data as any);
               result = _user;
            } catch (error) {
               debugLog(`*** GET for ${url} failed`);
               debugLog(`*** reason: ${(error as any).toString()}`);
               throw error;
            }
         } catch (acquireTokenSilentError) {
            if (acquireTokenSilentError instanceof InteractionRequiredAuthError) {
               instance.acquireTokenRedirect(accessTokenRequest);
            } else {
               debugLog((acquireTokenSilentError as any).toString());
            }
         }
      }

      return result;
   }

   static GetRedirectRequest() {
      const loadingPage = Capacitor.isNativePlatform()
         ? `${process.env.REACT_APP_WEBAPP_MOBILE_BASE_URL}Overview`
         : `${process.env.REACT_APP_WEBAPP_BASE_URL}Overview`;

      const authRequest = {
         authority: b2cPolicies.authorities.signUpSignIn.authority,
         scopes: stdScopes,
         postLogoutRedirectUri: loadingPage,
         redirectUri: loadingPage
      };

      return authRequest;
   };

   static async GetCurrentUser(instance: IPublicClientApplication): Promise<User | null | undefined> {
      const userJson = sessionStorage.getItem("user");
      if (userJson) {
         return JSON.parse(userJson) as User;
      }
      return await User.Load(instance);
   }
}

export class StateInfo {
   static Clone(data: any): StateInfo | StateInfo[] {
      if (Array.isArray(data)) {
         return data.map((x: any) => StateInfo.Clone(x) as StateInfo);
      }

      const _stateInfo = new StateInfo();

      _stateInfo.Id = data.Id ?? data.id;
      _stateInfo.Name = data.Name ?? data.name;
      _stateInfo.Abbreviation = data.Abbreviation ?? data.abbreviation;

      return _stateInfo;
   }

   Id?: number;
   Name?: string;
   Abbreviation?: string;
}

export class BankAccountType {
   static Clone(data: any): BankAccountType | BankAccountType[] {
      if (Array.isArray(data)) {
         return data.map((x: any) => BankAccountType.Clone(x) as BankAccountType);
      }

      const _bankAccountType = new BankAccountType();
      _bankAccountType.Id = data.Id ?? data.id;
      _bankAccountType.Name = data.Name ?? data.name;

      return _bankAccountType;
   }

   Id?: any;
   Name?: string;
}

export enum DebtType {
   None,
   PayingOn,
   NotPayingOn,
   Removed
}

export class CaseDeposit {
   static Clone(data: any): CaseDeposit | CaseDeposit[] {
      if (Array.isArray(data)) {
         return data.map((x: any) => CaseDeposit.Clone(x) as CaseDeposit);
      }

      const _caseDeposit = new CaseDeposit();

      _caseDeposit.PostedDate = data.PostedDate ?? data.postedDate;
      _caseDeposit.Amount = data.Amount ?? data.amount;
      _caseDeposit.DebtId = data.DebtId ?? data.debtId;

      return _caseDeposit;
   }

   PostedDate?: number;
   Amount?: number;
   DebtId?: string;
}

export class CaseFee {
   static Clone(data: any): CaseFee | CaseFee[] {
      if (Array.isArray(data)) {
         return data.map((x: any) => CaseFee.Clone(x) as CaseFee);
      }

      const _caseFee = new CaseFee();

      _caseFee.Title = data.Title ?? data.title;
      _caseFee.PaidToDate = data.PaidToDate ?? data.paidToDate;

      return _caseFee;
   }

   Title?: string | null;
   PaidToDate?: number;
}

export class CaseFeeDetail {
   static Clone(data: any): CaseFeeDetail | CaseFeeDetail[] {
      if (Array.isArray(data)) {
         return data.map((x: any) => CaseFeeDetail.Clone(x) as CaseFeeDetail);
      }

      const _caseFeeDetail = new CaseFeeDetail();

      _caseFeeDetail.Title = data.Title ?? data.title;
      _caseFeeDetail.PostedDate = data.PostedDate ?? data.postedDate;
      _caseFeeDetail.Amount = data.Amount ?? data.amount;

      return _caseFeeDetail;
   }

   Title?: string | null;
   PostedDate?: Date | null;
   Amount?: number;
}

export class CaseDebt {
   static Clone(data: any): CaseDebt | CaseDebt[] {
      if (Array.isArray(data)) {
         return data.map((x: any) => CaseDebt.Clone(x) as CaseDebt);
      }

      const _caseDebt = new CaseDebt();

      _caseDebt.DebtId = data.DebtId ?? data.debtId;
      _caseDebt.CreditorName = data.CreditorName ?? data.creditorName;
      _caseDebt.AccountNumber = data.AccountNumber ?? data.accountNumber;
      _caseDebt.OriginalBalance = data.OriginalBalance ?? data.originalBalance;
      _caseDebt.Apr = data.Apr ?? data.apr;
      _caseDebt.TotalPaidLastMonth = data.TotalPaidLastMonth ?? data.totalPaidLastMonth;
      _caseDebt.TotalPaidThisMonth = data.TotalPaidThisMonth ?? data.totalPaidThisMonth;
      _caseDebt.CurrentBalance = data.CurrentBalance ?? data.currentBalance;
      _caseDebt.MonthlyPayment = data.MonthlyPayment ?? data.monthlyPayment;
      _caseDebt.TotalPaidToDate = data.TotalPaidToDate ?? data.totalPaidToDate;
      _caseDebt.DebtType = data.DebtType ?? data.debtType;
      _caseDebt.LastCreditorPaymentDate = data.LastCreditorPaymentDate ?? data.lastCreditorPaymentDate;
      _caseDebt.PayoffDate = data.PayoffDate ?? data.payoffDate;
      _caseDebt.Deposits = data.Deposits ?? data.deposits;
      _caseDebt.ProposalStatus = data.ProposalStatus ?? data.proposalStatus;

      return _caseDebt;
   }

   DebtId?: string;
   CreditorName?: string;
   AccountNumber?: string;
   OriginalBalance?: number;
   Apr?: number;
   TotalPaidLastMonth?: number;
   TotalPaidThisMonth?: number;
   CurrentBalance?: number;
   MonthlyPayment?: number;
   TotalPaidToDate?: number;
   DebtType?: DebtType;
   LastCreditorPaymentDate?: string | null;
   PayoffDate?: Date | null;
   Deposits?: CaseDeposit[];
   ProposalStatus?: string;

   static Progress: (item: CaseDebt) => number = (item: CaseDebt) => {
      const start = item.OriginalBalance ?? 0;
      const current = item.CurrentBalance ?? 0;

      return start > 0 && current >= 0
         ? (current > start
            ? 0
            : (start - current) / start)
         : 0;
   };

   static DebtTypeDisplay: (debtType: DebtType) => string = (debtType: DebtType) => {
      switch (debtType) {
         case DebtType.None:
            return "None";
         case DebtType.PayingOn:
            return "Paying";
         case DebtType.NotPayingOn:
            return "Paid";
         case DebtType.Removed:
            return "Removed";
      }
      return "Unknown";
   };

}
