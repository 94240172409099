// #region packages
import { IonFooter } from "@ionic/react";
// #endregion
// #region components
import OpenPage from "./OpenPage";
// #endregion

interface AppFooterProps {
}

const AppFooter = ( props: AppFooterProps ) => {
   return (
      <IonFooter>
         <OpenPage url="https://www.apprisen.com/privacy/">Privacy Policy</OpenPage> - Copyright - &copy; 2022-2024 Apprisen. All rights reserved. 800.355.2227 700 Taylor Rd. Ste 190 Gahanna, OH 43230.
      </IonFooter>
   );
};

export default AppFooter;
