import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as analytics from "../utilities/ga4";

export function useAnalytics() {
   const location = useLocation();

   useEffect(() => {
      analytics.init();
   }, []);

   useEffect(() => {
          analytics.sendPageview(location?.pathname ?? window.location.pathname);
      }, [location]);
}

export default useAnalytics;
