// #region packages
import { useState, Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
   IonButton,
   IonCard,
   IonCol,
   IonContent,
   IonDatetime,
   IonDatetimeButton,
   IonGrid,
   IonInput,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonModal,
   IonPage,
   IonRow,
   IonSelect,
   IonSelectOption,
   IonSpinner,
   IonTextarea,
   useIonRouter,
} from "@ionic/react";
import axios from "axios";
// #endregion
// #region components
import AppFooter from "../components/AppFooter";
import ErrorBadge from "../components/ErrorBadge";
import OpenPage from "../components/OpenPage";
import SubpageHeader from "../components/SubpageHeader";
import Toaster, { popError } from "../components/Toaster";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region interfaces
import { GenericResponse } from "../interfaces/GenericResponse";
import { BankAccountType, User } from "../interfaces/User";
// #endregion
// #region utilities
import { StdErrorMsg } from "../utilities/Constants";
import { debugLog } from "../utilities/debugLog";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
// #region Azure B2C
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-common";
import { protectedResources } from "../authConfig";
// #endregion

interface PaymentRequest {
   clientNumber?: number;
   caseNumber: bigint;
   effectiveDate: Date;
   routingNumber: string;
   accountNumber: string;
   amount: number;
   bankAccountType: any;
   primaryNameOnAccount: string;
   clientComments: string;
}

interface PaymentResponse extends GenericResponse {
   confirmationNumber: string;
}

interface MakePaymentProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// Helper functions for the initial date calculations
const addDays = (numDays: number, date = new Date()) => {
  const result = new Date(date);
  result.setDate(date.getDate() + numDays);
  return result;
};

function isWeekday(date: Date): Boolean {
  const utcDay = date.getUTCDay();
  return utcDay !== 0 && utcDay !== 6;
}

function calcInitialDates(): { initFirstPaymentDate: Date, initLastPaymentDate: Date, initValidDates: Date[] } {
  let validDates: Date[] = [];
  const now = new Date();
  const isBefore1PM = now.getHours() < 13;
  let firstPaymentDate = isBefore1PM ? now : addDays(1, now);

  while (!isWeekday(firstPaymentDate))
     firstPaymentDate = addDays(1, firstPaymentDate);

  firstPaymentDate.setHours(0, 0, 0, 0);

  validDates.push(firstPaymentDate);
  let lastPaymentDate = firstPaymentDate;

  for (let dayCount = 0; dayCount < 4; ++dayCount) {
     lastPaymentDate = addDays(1, lastPaymentDate);

     while (!isWeekday(lastPaymentDate)) {
        lastPaymentDate = addDays(1, lastPaymentDate);
     }

     validDates.push(lastPaymentDate);
  }

  lastPaymentDate.setHours(0, 0, 0, 0);

  debugLog("First payment date: " + firstPaymentDate.toISOString());
  debugLog("Last payment date: " + lastPaymentDate.toISOString());
  debugLog("Valid dates: " + validDates);

  return { initFirstPaymentDate: firstPaymentDate, initLastPaymentDate: lastPaymentDate, initValidDates: validDates };
}

function areDateDaysEqual(date1: Date, date2: Date): Boolean {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate(); 
}

function shortLocalISO(date: Date): string {
  function pad(value: number) {
    if (value < 10) {
      return '0' + value;
    }
    return value;
  }

  return `${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())}`;
}

// This assumes U.S. times which will always be -0#:00
function getLocalISOOffsetSuffix(): string {
  const offset = new Date().getTimezoneOffset();
  return `T00:00:00-0${offset / 60}:00`;
}

// Initialization values for useState until useEffect calculates initial dates
const minDate = new Date("1900-01-01T00:00:00.000Z");
const maxDate = new Date("2100-12-31T23:59:59.999Z");

// ReSharper disable once UnusedParameter
const MakePayment: React.FC<MakePaymentProps> = (props) => {
   debugLog("RENDERING MakePayment")

   const user = props.user;
   const localISOOffsetSuffix = getLocalISOOffsetSuffix();

   useIris();
   useFeedbackButton();

   const history = useIonRouter();
   const { instance } = useMsal();
   const [accountValidated, setAccountValidated] = useState<boolean | null>(null);

   const [ firstPaymentDate, setFirstPaymentDate ] = useState<Date>(minDate);
   const [ lastPaymentDate, setLastPaymentDate ] = useState<Date>(maxDate);
   const [ validDates, setValidDates ] = useState<Date[]>([]);

   const requestPayload = {
    clientNumber: user?.ClientNumber,
    caseNumber: user?.DmpCaseId,
    effectiveDate: firstPaymentDate,
    routingNumber: null as unknown as string,
    accountNumber: null as unknown as string,
    amount: 0,
    bankAccountType: null as unknown as any,
    primaryNameOnAccount: null as unknown as string,
    clientComments: null as unknown as string
 } as PaymentRequest;

 const { register, handleSubmit, formState: { errors, isSubmitting, }, getValues, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
       data: requestPayload
    },
    criteriaMode: "all"
 });

   useEffect(() => {
      debugLog("MakePayment: useEffect - calcInitialDates");
      const { initFirstPaymentDate, initLastPaymentDate, initValidDates } = calcInitialDates();
      setFirstPaymentDate(initFirstPaymentDate);
      setLastPaymentDate(initLastPaymentDate);
      setValidDates(initValidDates);
      setValue("data.effectiveDate", initFirstPaymentDate);
   }, [setValue]); // No warning issued about dependencies on the useState "set" functions

   // Called by IonDatetime component to verify which dates it should enable in the popup calendar
   // It only passes the YYYY-MM-DD part of the date string, so we add a local time suffix
   const isDateEnabled = (dateString: string): boolean => {
      const dateToCheck = new Date(`${dateString}${localISOOffsetSuffix}`);
      const found = validDates.some((x) => areDateDaysEqual(x, dateToCheck));
      debugLog(`MakePayment: isDateEnabled - ${dateString}: ${found ? "WAS" : "WAS NOT"} FOUND`)
      return found;
   };

   // Called by IonDatetime component when a new date is selected in the popup calendar
   // It only passes the YYYY-MM-DD part of the date string, so we add a local time suffix
   const setPaymentDate = (paymentDate: string) => {
      debugLog("MakePayment: setPaymentDate: " + paymentDate);
      let newPaymentDate = new Date(`${paymentDate}${localISOOffsetSuffix}`);
      setValue("data.effectiveDate", newPaymentDate);
   }

   const bankAccountTypes = (user?.BankAccountTypes ?? []) as (BankAccountType[] | null);

   const validateAccountCall = async (accessToken: string, data: PaymentRequest) => {
      const routingNumber = data.routingNumber;
      const accountNumber = data.accountNumber;

      let result = null;

      if (routingNumber && accountNumber) {

         try {
            const url = protectedResources.validateBankAccount.endpoint;
            debugLog("*** Attempting to contact " + url);

            const apiResponse = await axios.post<boolean>(
               url, {
               RoutingNumber: routingNumber,
               AccountNumber: accountNumber,
               cache: false
            }, {
               headers: {
                  Authorization: `Bearer ${accessToken}`
               }
            });

            result = apiResponse.data;
            if (!apiResponse.data) {
               popError("Incorrect bank routing/account numbers");
            }
         } catch (error) {
            if ((error as any).code === "ERR_NETWORK") {
               popError("Error contacting server. Please check your network connection");
            } else {
               popError(StdErrorMsg("Error validating bank routing/account numbers."));
            }
            result = false;
         }
      }

      return result;
   };

   const validateAccount = async (data: PaymentRequest) => {
      try {
         const account = instance.getActiveAccount() as AccountInfo | undefined;
         const accessTokenRequest = {
            scopes: protectedResources.validateBankAccount.scopes,
            account: account
         };

         const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
         const accessToken = accessTokenResponse.accessToken;
         return await validateAccountCall(accessToken, data);
      }
      catch (error) {
         debugLog((error as any).toString());
         popError(StdErrorMsg("Error validating bank routing/account numbers."));
      };

      return false;
   };

   const createWebPaymentCall = async (accessToken: string, request: { data: PaymentRequest; }) => {
      const dataObj = request.data as PaymentRequest;

      if (!await validateAccount(dataObj)) {
         setAccountValidated(false);
         return;
      }

      setAccountValidated(true);

      try {
         const url = protectedResources.createWebPayment.endpoint;

         debugLog("*** Attempting to contact " + url);
         const apiResponse = await axios.post<PaymentResponse>(
            url, {
               cache: false,
            ...dataObj
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         });

         const err: Array<{ key: string, value: string; }> = apiResponse.data?.Errors;
         const successful = (!err || err.length === 0);

         if (successful) {
            history.push(`/PaymentConfirmation?conf=${apiResponse.data.confirmationNumber}`);
         } else {
            err.forEach((e) => popError(e.value));
         }

      } catch (error) {
         if ((error as any).code === "ERR_NETWORK") {
            popError("Error contacting server. Please check your network connection.");
         } else {
            popError(StdErrorMsg("Error sending payment."));
         }
      }
   };

   const createWebPayment = (request: { data: PaymentRequest; }) => {
      const account = instance.getActiveAccount() as AccountInfo | undefined;
      const accessTokenRequest = {
         scopes: protectedResources.createWebPayment.scopes,
         account: account
      };

      instance.acquireTokenSilent(accessTokenRequest)
         .then((accessTokenResponse) => {
            const accessToken = accessTokenResponse.accessToken;
            createWebPaymentCall(accessToken, request);
         })
         .catch((error) => {
            debugLog(error);
            popError(StdErrorMsg("Error sending payment."));
         });
   };

   return (
      <IonPage>
         <SubpageHeader title="Make a Payment" parentPage="/Overview" />

         <IonContent id="makePayment">
            <form id="makePaymentForm" onSubmit={handleSubmit(createWebPayment)}>
               <IonGrid>
                  <IonRow>
                     <IonCol size="12" sizeLg="6" offsetLg="3">
                        <IonCard>
                           <IonList className="ion-no-padding">
                              <IonListHeader
                                 className="white ion-text-center ion-padding-end">
                                 <IonLabel>
                                    <h2>Payment Details</h2>
                                 </IonLabel>
                              </IonListHeader>

                              <IonItem>
                                 <ul className="list">
                                    <li>Payments can be scheduled up to five (5) business days in advance.</li>
                                    <li>Payments will post to your Apprisen account five (5) business days after they are taken from your bank account.</li>
                                    <li>Same day payments cannot be made after 1:00pm ET.</li>
                                    <li>
                                       Making an online payment does not automatically cancel any AutoPay drafts that may be scheduled for the month.
                                       If you are enrolled in our automated payment program and need to cancel your ACH draft, please indicate this in
                                       the comment section with your online payment, or contact one of our representatives at&nbsp;
                                       <OpenPage url="mailto:clientsuccess@apprisen.com" >clientsuccess@apprisen.com</OpenPage>, by calling <OpenPage url="tel:800-355-2227">800.355.2227</OpenPage>,
                                       or through our IRIS chat.
                                    </li>
                                 </ul>
                              </IonItem>

                              <IonItem lines="none">
                                 <IonLabel className="required" position="stacked">
                                    Amount (between $10 and $25,000)
                                 </IonLabel>
                                 <IonInput
                                    inputmode="decimal"
                                    type="number"
                                    placeholder="Payment Amount"
                                    {...register("data.amount",
                                       {
                                          required: true,
                                          min: 10,
                                          max: 25000
                                       })} />
                                 {errors.data?.amount && errors.data?.amount.type === "required" &&
                                    <ErrorBadge>Payment amount is required</ErrorBadge>}
                                 {errors.data?.amount && (errors.data?.amount.type === "min" || errors.data?.amount.type === "max") &&
                                    <ErrorBadge>Payment amount must be a number between $10 and $25,000</ErrorBadge>}
                              </IonItem>

                              <IonItem lines="none">
                                 <IonLabel position="stacked">
                                    Payment Date
                                 </IonLabel>
                                 <div className="input calendar-input" >
                                    { (firstPaymentDate > minDate) && // Defer rendering until initial dates calculated
                                    <>
                                    <IonDatetimeButton datetime="calendar" color="light" />
                                    <IonModal keepContentsMounted={true} backdropDismiss={false}>
                                       <IonDatetime id="calendar"
                                          /*{...register("data.effectiveDate")} INTENTIONALLY COMMENTED OUT - induces a bug, see MYAP-341*/
                                          presentation="date"
                                          showDefaultButtons={true}
                                          value={shortLocalISO(getValues("data.effectiveDate"))}
                                          isDateEnabled={isDateEnabled}
                                          min={shortLocalISO(firstPaymentDate)}
                                          max={shortLocalISO(lastPaymentDate)}
                                          onIonChange={(e) => setPaymentDate(e.detail.value! as string)}
                                       />
                                    </IonModal>
                                    </>
                                    }
                                 </div>
                              </IonItem>

                              <IonItem lines="none">
                                 <IonLabel position="stacked">
                                    Comment
                                 </IonLabel>
                                 <IonTextarea
                                    rows={5}
                                    spellcheck={true}
                                    autoGrow={true}
                                    autocapitalize="sentences"
                                    autoCorrect="on"
                                    enterkeyhint="enter"
                                    placeholder="Comment"
                                    {...register("data.clientComments")} />
                              </IonItem>
                           </IonList>
                        </IonCard>

                        <IonCard>
                           <IonList className="ion-no-padding">
                              <IonListHeader
                                 className="white ion-text-center ion-padding-end">
                                 <IonLabel>
                                    <h2>Banking Information</h2>
                                 </IonLabel>
                              </IonListHeader>

                              <IonItem lines="none">
                                 <IonLabel className="required" position="stacked">
                                    Account Type
                                 </IonLabel>
                                 <IonSelect
                                    interface="popover"
                                    placeholder="Select One"
                                    {...register("data.bankAccountType", { required: true })}>
                                    {
                                       bankAccountTypes?.map((opt) => {
                                          return (
                                             <IonSelectOption key={opt.Id} value={opt.Id}>{opt.Name}</IonSelectOption>
                                          );
                                       })
                                    }
                                 </IonSelect>
                                 {errors.data?.bankAccountType &&
                                    <ErrorBadge>Account type is required</ErrorBadge>}
                              </IonItem>

                              <IonItem lines="none">
                                 <IonLabel className="required" position="stacked">
                                    Routing Number
                                 </IonLabel>
                                 <IonInput
                                    inputmode="numeric"
                                    placeholder="Routing Number"
                                    {...register("data.routingNumber", { required: true })} />
                                 {errors.data?.routingNumber && errors.data?.routingNumber.type === "required" &&
                                    <ErrorBadge>Routing number is required</ErrorBadge>}
                                 {errors.data?.routingNumber && (errors.data?.routingNumber.type === "minlength" || errors.data?.routingNumber.type === "maxlength") &&
                                    <ErrorBadge>Routing numbers are nine digits long</ErrorBadge>}
                                 {accountValidated === false &&
                                    <ErrorBadge>Incorrect bank routing/account number combination</ErrorBadge>}
                              </IonItem>

                              <IonItem lines="none">
                                 <IonLabel className="required" position="stacked">
                                    Account Number
                                 </IonLabel>
                                 <IonInput
                                    placeholder="Account Number"
                                    {...register("data.accountNumber", { required: true })} />
                                 {errors.data?.accountNumber &&
                                    <ErrorBadge>Account Number is required</ErrorBadge>}
                                 {accountValidated === false &&
                                    <ErrorBadge>Incorrect bank routing/account number combination</ErrorBadge>}
                              </IonItem>

                              <IonItem lines="none">
                                 <IonLabel className="required" position="stacked">
                                    Primary Name on Account
                                 </IonLabel>
                                 <IonInput
                                    placeholder="Name"
                                    {...register("data.primaryNameOnAccount",
                                       { required: "This entry is required" })} />
                                 {errors.data?.primaryNameOnAccount &&
                                    <ErrorBadge>Primary Name on Account is required</ErrorBadge>}
                              </IonItem>
                           </IonList>

                           <IonItem>
                              <IonButton
                                 className="full-button"
                                 disabled={isSubmitting}
                                 expand="full"
                                 type="submit">
                                 {
                                    isSubmitting &&
                                    (
                                       <Fragment>
                                          Submitting Payment <IonSpinner style={{ marginLeft: "12px" }} />
                                       </Fragment>
                                    )
                                 }
                                 {
                                    !isSubmitting &&
                                    (
                                       <span>Submit Payment</span>
                                    )
                                 }
                              </IonButton>
                           </IonItem>

                        </IonCard>
                     </IonCol>
                  </IonRow>
               </IonGrid>
            </form>
            <p className="withIris">&nbsp;</p>
         </IonContent>
         <Toaster />
         <AppFooter />
         <AppIdleTimer logout={props.logout} />
      </IonPage>
   );
};

export default MakePayment;
