import { Fragment } from "react";
// #region packages
import {
   IonButton,
   IonCard,
   IonCol,
   IonContent,
   IonGrid,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonPage,
   IonRow
} from "@ionic/react";
import { Redirect } from "react-router-dom";
// #endregion
// #region components
import Header from "../components/Header";
import WelcomeBanner from "../components/WelcomeBanner";
// #endregion
// #region interfaces
import { User } from "../interfaces/User";
// #endregion
// #region utilities
import { debugLog } from "../utilities/debugLog";
// #endregion
// #region Azure B2C
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
// #endregion

interface LoginProps {
   login: () => void;
   user: User;
}

const Login: React.FC<LoginProps> = (props) => {
   const user = props.user;

   const doLogin = (e: any) => {
      debugLog("doLogin");
      e.preventDefault();
      props.login();
   };

   const loginTemplate = (<IonPage>
      <Header title="Login" />
      <IonContent id="contact">
         <IonGrid>

            <IonRow>
               <IonCol size="12" sizeLg="8" offsetLg="2">
                  <WelcomeBanner />
               </IonCol>
            </IonRow>

            <IonRow>
               <IonCol size="12" sizeLg="8" offsetLg="2">
                  <IonCard>
                     <IonList className="ion-no-padding">
                        <IonListHeader className="white" >
                           <IonLabel>
                              <h2>Login to your account</h2>
                           </IonLabel>
                        </IonListHeader>
                        <IonItem lines="none">
                           We're a non-profit organization committed to your financial well-being.
                           For over 65 years, we've been helping, empowering, and motivating people
                           along their financial journey.
                        </IonItem>
                        <IonItem lines="none">
                           If you're a client of ours, please&nbsp; <IonButton onClick={doLogin}>Login</IonButton>.
                        </IonItem>
                        <IonItem lines="none">
                           Or, if you're a client but don't yet have an online account, you can <IonButton onClick={doLogin}>create one</IonButton>.
                        </IonItem>
                     </IonList>
                  </IonCard>
               </IonCol>
            </IonRow>
         </IonGrid>
      </IonContent>
   </IonPage>
   );

   return (
      <Fragment>
         <UnauthenticatedTemplate>
            {loginTemplate}
         </UnauthenticatedTemplate>
         <AuthenticatedTemplate>
            {user ? <Redirect to="/Overview" /> : loginTemplate}
         </AuthenticatedTemplate>
      </Fragment>
   );

};

export default Login;
