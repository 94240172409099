import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import Header from "../components/Header";
import { Hourglass } from "react-loader-spinner";
import Toaster from "../components/Toaster";
import AppFooter from "../components/AppFooter";

const Loading: React.FC = () => {
   return (
      <IonPage>
         <Header />
         <IonContent id="overview">
            <div style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               paddingTop: "3em"
            }}>
               <Hourglass wrapperClass="loader" colors={["#67B642", "whitesmoke"]} ariaLabel="Please wait" />
               <h2>Loading your information<br />One moment please...</h2>
            </div>
         </IonContent>
         <Toaster />
         <AppFooter />
      </IonPage>
   );
};

export default Loading;