import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { debugLog } from "../utilities/debugLog";

interface LogoutProps {
   logout: () => void;
}

const Logout: React.FC<LogoutProps> = (props) => {
   const { instance } = useMsal();
   useEffect(() => {
      if (instance.getAllAccounts()?.length > 0) {
         // first sign out of MSAL. This will cause a callback to this page.
         debugLog("Logging out");
         props.logout();
      } else {
         // on the callback, clear the session storage and redirect to the login page
         debugLog("Callback from B2C. Clearing cache.");
         sessionStorage.clear();
      }

   }, [instance, props]);

   return null;
};

export default Logout;
