import { useEffect, useCallback, useState, Fragment } from "react";

// #region packages
import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";
import { Redirect, Route, Switch } from "react-router-dom";
import { IonApp, setupIonicReact, useIonRouter } from "@ionic/react";
import { ErrorBoundary } from "react-error-boundary";
// #endregion
// #region pages
import About from "./pages/About";
import AccountOverview from "./pages/AccountOverview";
import AdditionalResources from "./pages/AdditionalResources";
import ChangeAutopay from "./pages/ChangeAutopay";
import Contact from "./pages/Contact";
import Fees from "./pages/Fees";
import LenderOverview from "./pages/LenderOverview";
import Loading from "./pages/Loading";
import Logout from "./pages/Logout";
import Login from "./pages/Login";
import MakePayment from "./pages/MakePayment";
import Overview from "./pages/Overview";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import Profile from "./pages/Profile";
// #endregion
// #region components
import AppUrlListener from "./components/AppUrlListener";
// #endregion
// #region interfaces
import { User } from "./interfaces/User";
// #endregion
// #region utilities
import { debugLog } from "./utilities/debugLog";
// #endregion
// #region hooks
import { useAnalytics } from "./hooks/useAnalytics";
// #endregion
// #region Azure B2C
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionType, EventType, AccountInfo } from "@azure/msal-browser";
import { stdScopes, b2cPolicies } from "./authConfig";
// #endregion
// #region styles

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
// #endregion

setupIonicReact({
   mode: "md"
});

interface AppProps { }

const App: React.FC<AppProps> = (props) => {
   useAnalytics();

   const { instance, inProgress, logger } = useMsal();
   const history = useIonRouter();

   const [user, setUser] = useState(null as User | null);
   const [getUserError, setGetUserError] = useState(null as any);
   const [userLoading, setUserLoading] = useState(false);

   const getCurrentUser = useCallback(async () => {
      const fetchUser = async () => {
         let result: User | null = null;

         if (!userLoading) {
            try {
               debugLog("useCurrentUserData.fetchUser");
               setUserLoading(true);

               debugLog("useCurrentUserData.fetchUser: trying session storage");
               const userJson = sessionStorage.getItem("user");

               if (userJson) {
                  debugLog("useCurrentUserData.fetchUser: found in session storage");
                  result = JSON.parse(userJson) as User | null;
                  debugLog("useCurrentUserData.fetchUser: session storage returned " + JSON.stringify(result));
               } else {
                  debugLog("useCurrentUserData.fetchUser: trying User.Load");
                  result = await User.Load(instance) as User | null;
                  debugLog("useCurrentUserData.fetchUser: User.Load returned " + JSON.stringify(result));
               }

               setGetUserError(null);
               setUser(result);

               if (result) {
                  sessionStorage.setItem("user", JSON.stringify(result));
               } else {
                  sessionStorage.removeItem("user");
               }
            } catch (e) {
               debugLog("useCurrentUserData.fetchUser: error = " + JSON.stringify(e));
               setGetUserError(e);
               setUser(null);
               sessionStorage.removeItem("user");
            } finally {
               setUserLoading(false);
            }
         }

         return result;
      };
      debugLog("getCurrentUser - getting user data");
      if (!user && !userLoading) {
         fetchUser();
      }
      debugLog("getCurrentUser: returning " + JSON.stringify([user, getUserError, userLoading]));
      return [user, getUserError, userLoading];
   }, [instance, user, userLoading, getUserError]);

   const getRedirectRequest = () => {
      const loadingPage = Capacitor.isNativePlatform()
         ? `${process.env.REACT_APP_WEBAPP_MOBILE_BASE_URL}Overview`
         : `${process.env.REACT_APP_WEBAPP_BASE_URL}Overview`;

      const authRequest = {
         authority: b2cPolicies.authorities.signUpSignIn.authority,
         scopes: stdScopes,
         postLogoutRedirectUri: loadingPage,
         redirectUri: loadingPage
      };

      return authRequest;
   };

   const logout = useCallback(() => {
      if (inProgress === InteractionStatus.None) {
         logger.info("Logging out");
         instance.logoutRedirect(getRedirectRequest());
      }
   }, [inProgress, instance, logger]);

   const login = useCallback(() => {
      if (inProgress === InteractionStatus.None) {
         logger.info("Logging in");
         instance.loginRedirect(getRedirectRequest());
      }
   }, [inProgress, instance, logger]);

   useEffect(() => {
      instance.addEventCallback(async (event: any) => {
         switch (event.eventType) {
            case EventType.LOGIN_SUCCESS:
            case EventType.SSO_SILENT_SUCCESS:
            case EventType.ACQUIRE_TOKEN_SUCCESS:
            case EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS:
               const account = event.payload as (AccountInfo | null);
               debugLog(`Setting active account from ${event.eventType} event: ${account}`);
               instance.setActiveAccount(account);
               getCurrentUser();

               break;
            case EventType.LOGIN_FAILURE:
            case EventType.SSO_SILENT_FAILURE:
            case EventType.ACQUIRE_TOKEN_FAILURE:
            case EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE:
               sessionStorage.removeItem("user");
               setUser(null);
               setGetUserError(event.error);

               if (event.error) {
                  if (event.error.errorMessage.includes("AADB2C90118")) {
                     // forgot password
                     const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.resetPassword.authority,
                        scopes: [],
                     };
                     instance.loginRedirect(resetPasswordRequest);
                  } else {
                     instance.loginRedirect(getRedirectRequest());
                  }
               } else {
                  instance.loginRedirect(getRedirectRequest());
               }

               break;
            case EventType.LOGOUT_SUCCESS:
               sessionStorage.removeItem("user");
               setUser(null);
               setGetUserError(event.error);
               break;
         }
      });

      if (Capacitor.isNativePlatform()) {
         CapacitorApp.addListener("backButton",
            () => {
               if (window.location.pathname === "/Overview" ||
                  window.location.pathname === "/Login" ||
                  window.location.pathname === "/") {
                  const ans = window.confirm("Exit - Are you sure?");
                  if (ans) {
                     CapacitorApp.exitApp();
                  }
               }
            });
      }
   }, [user, instance, history, login, getCurrentUser]);

   return (
      <Fragment>
         <AppUrlListener></AppUrlListener>
         <ErrorBoundary fallback={<Redirect to="/login" />}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={getRedirectRequest()}>
               <IonApp>
                  {user ? (
                     <Switch>
                        <Route path="/About" render={(routeProps) => { return (<About user={user} login={login} logout={logout} {...routeProps} />); }} />
                        <Route path="/AccountOverview" render={(routeProps) => { return (<AccountOverview user={user} login={login} logout={logout} {...routeProps} />); }} />
                        <Route path="/AdditionalResources" render={(routeProps) => { return (<AdditionalResources user={user} login={login} logout={logout}  {...routeProps} />); }} />
                        <Route path="/ChangeAutopay" render={(routeProps) => { return (<ChangeAutopay user={user} login={login} logout={logout} {...routeProps} />); }} />
                        <Route path="/Contact" render={(routeProps) => { return (<Contact user={user} login={login} logout={logout} {...routeProps} />); }} />
                        <Route path="/Fees" render={(routeProps) => { return (<Fees user={user} login={login} logout={logout} {...routeProps} />); }} />
                        <Route path="/LenderOverview" render={(routeProps) => { return (<LenderOverview user={user} login={login} logout={logout} {...routeProps} />); }} />
                        <Route path="/Login" render={(routeProps) => { return (<Login user={user} login={login} {...routeProps} />); }} />
                        <Route path="/Logout" render={(routeProps) => { return (<Logout logout={logout} {...routeProps} />); }} />
                        <Route path="/MakePayment" render={(routeProps) => { return (<MakePayment user={user} login={login} logout={logout} {...routeProps} />); }} />
                        <Route path="/Overview" render={(routeProps) => { return (<Overview user={user} login={login} logout={logout}  {...routeProps} />); }} />
                        <Route path="/PaymentConfirmation" render={(routeProps) => { return (<PaymentConfirmation user={user} login={login} logout={logout}  {...routeProps} />); }} />
                        <Route path="/Profile" render={(routeProps) => { return (<Profile user={user} login={login} logout={logout}  {...routeProps} />); }} />
                        <Route render={(routeProps) => { return (<Login user={user} login={login} {...routeProps} />); }} />
                        <Redirect exact from="/" to="/Login" />
                     </Switch>
                  ) : (
                     <Loading />
                  )}
               </IonApp >
            </MsalAuthenticationTemplate>
         </ErrorBoundary>
      </Fragment>);
};

export default App;
