// #region packages
import { IonRouterLink } from "@ionic/react";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
// #endregion

interface ResourceProps extends React.HTMLAttributes<HTMLIonRouterLinkElement> {
   url: string;
   children?: React.ReactNode;
}

const OpenPage = ( props: ResourceProps ) => {
   return (
      <IonRouterLink
         tabIndex={ -1 }
         onClick={ ( e ) => {
            e.preventDefault();
            InAppBrowser.create( props.url, "_system", "location=yes" );
         } }>
         { props.children }
      </IonRouterLink>
   );
};

export default OpenPage;
