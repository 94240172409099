import { Fragment } from "react";
// #region packages
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import {
   IonCard,
   IonCol,
   IonContent,
   IonGrid,
   IonLabel,
   IonList,
   IonListHeader,
   IonPage,
   IonRow
} from "@ionic/react";
// #endregion
// #region components
import Header from "../components/Header";
import Menu from "../components/Menu";
import AppFooter from "../components/AppFooter";
import Resource from "../components/Resource";
import WelcomeBanner from "../components/WelcomeBanner";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region interfaces
import { User } from "../interfaces/User";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
interface AdditionalResourcesProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const AdditionalResources: React.FC<AdditionalResourcesProps> = (props) => {
   useIris();
   useFeedbackButton();

   const handleClick = (url: string) => {
      InAppBrowser.create(url, "_system", "location=yes");
   };


   return (
      <Fragment>
         <Menu contentId="resources" />
         <IonPage>
            <Header title="Additional Resources" />
            <IonContent id="resources">
               <IonGrid>
                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <WelcomeBanner />
                     </IonCol>
                  </IonRow>

                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <IonRow>
                           <IonCol size="12" sizeLg="6">
                              <IonCard >
                                 <IonList className="ion-no-padding">
                                    <IonListHeader className="white">
                                       <IonLabel>
                                          <h2>Additional Resources</h2>
                                       </IonLabel>
                                    </IonListHeader>
                                    <Resource
                                       title="Facebook DMP Community"
                                       icon="/images/facebook-apprisen.png"
                                       url="https://www.facebook.com/groups/ApprisenDMPCommunity"
                                       onClickHandler={handleClick} />
                                    <Resource
                                       title="Apprisen Money Minute Blog"
                                       icon="/images/apprisen-logo.png"
                                       url="https://www.apprisen.com/blog/"
                                       onClickHandler={handleClick} />
                                    <Resource
                                       title="United Way"
                                       icon="/images/united-way.jpg"
                                       url="https://www.unitedway.org/"
                                       onClickHandler={handleClick} />
                                    <Resource
                                       title="Financial Education Resources"
                                       icon="/images/cfpb.png"
                                       url="https://www.consumerfinance.gov/consumer-tools/"
                                       onClickHandler={handleClick} />
                                    <Resource
                                       title="Apprisen Resources"
                                       icon="/images/apprisen-logo.png"
                                       url="https://www.apprisen.com/resources/"
                                       onClickHandler={handleClick} />
                                    <Resource
                                       title="IRIS ConnectED"
                                       icon="/images/iris.png"
                                       url="https://education.apprisen.com/offers/J7oQX6w3?coupon_code=DMPFREE"
                                       onClickHandler={handleClick} />
                                 </IonList>
                              </IonCard>
                           </IonCol>
                           <IonCol size="12" sizeLg="6" >
                              <IonCard >
                                 <IonList className="ion-no-padding">
                                    <IonListHeader className="white">
                                       <IonLabel>
                                          <h2>Social Media</h2>
                                       </IonLabel>
                                    </IonListHeader>
                                    <Resource
                                       title="Facebook"
                                       icon="/images/facebook-apprisen.png"
                                       url="https://www.facebook.com/Apprisen/"
                                       onClickHandler={handleClick} />
                                    <Resource
                                       title="Instagram"
                                       icon="/images/instagram.png"
                                       url="https://www.instagram.com/apprisenempowers/"
                                       onClickHandler={handleClick} />
                                    <Resource
                                       title="Twitter"
                                       icon="/images/twitter.jpg"
                                       url="https://twitter.com/Apprisen"
                                       onClickHandler={handleClick} />
                                 </IonList>
                              </IonCard>
                           </IonCol>
                        </IonRow>
                     </IonCol>
                  </IonRow>
               </IonGrid>
               <p className="withIris">&nbsp;</p>
            </IonContent>
            <AppFooter />
            <AppIdleTimer logout={props.logout} />
         </IonPage>
      </Fragment>
   );
};

export default AdditionalResources;
