export const currency = ( value: number | string | null | undefined ) => {
   if ( value ) {
      const formatter = new Intl.NumberFormat( 'en-US',
         {
            style: 'currency',
            currency: 'USD'
         } );
      try {
         const currencyValue = typeof value === 'string' ? Number.parseFloat( value ) : value;
         return formatter.format( currencyValue );
      } catch ( e ) {
         return null;
      }
   }
   return null;
};

export const date = ( value: Date | string | null | undefined ) => {
   if ( value ) {
      const formatter = new Intl.DateTimeFormat( 'en-US' );
      try {
         const dateValue = typeof value === 'string' ? Date.parse( value ) : value;
         return formatter.format( dateValue );
      } catch ( e ) {
         return null;
      }
   }
   return null;
};

export const printDate = ( date: Date ) => {
   if ( date ) {
      const d = new Date( date );
      const month = d.getMonth() + 1;
      const day = d.getDate();
      const year = d.getFullYear();
      return month + "/" + day + "/" + year;
   }
   return "";
};

export const addDays = ( numDays: number, date = new Date() ) => {
   let result = new Date( date );
   result.setDate( date.getDate() + numDays );
   return result;
};

