import { Fragment } from "react";
// #region packages
import {
   IonItem,
   IonLabel
} from "@ionic/react";
// #endregion

// Allows for 2- or 3-column display. 
// For flush left and right, use "value" property. For a middle column, use "value1" and "value2".
// Using "value2" without "value1" is the same as using "value"
interface ListDisplayItemProps {
   name: string;
   value?: string;
   value1?: string;
   value2?: string;
}

const ListDisplayItem = (props: ListDisplayItemProps) => {
   return (
      <IonItem>
         <IonLabel>
            <h3>{props.name}</h3>
         </IonLabel>
         <IonLabel>
            <div className="flex-grid">
               {
                  props.value !== undefined ? (
                     <Fragment>
                        <div className="single-card-grid" />
                        <div className="single-card-grid">
                           <h3 className="ion-text-right">
                              {props.value}
                           </h3>
                        </div>
                     </Fragment>
                  ) : (
                     <Fragment>
                        <div className="single-card-grid">
                           {
                              (props.value1 !== undefined) ? (
                                 <h3 className="ion-text-left">
                                    {props.value1}
                                 </h3>
                              ) : (<Fragment />)
                           }
                        </div>
                        <div className="single-card-grid">
                           {
                              (props.value2 !== undefined) ? (
                                 <h3 className="ion-text-right">
                                    {props.value2}
                                 </h3>
                              ) : (<Fragment />)
                           }
                        </div>

                     </Fragment>
                  )
               }
            </div>
         </IonLabel>
      </IonItem>
   );
};

export default ListDisplayItem;
