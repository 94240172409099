// #region packages
import { IonItem, IonLabel, IonThumbnail } from "@ionic/react";
// #endregion

interface ResourceProps {
   title: string;
   icon: string;
   url: string;
   onClickHandler: ( url: string ) => void | null;
}

const Resource = ( props: ResourceProps ) => {
   return (
      <IonItem style={ { cursor: "pointer" } } onClick={ ( e ) => {
         e.preventDefault();
         props.onClickHandler && props.onClickHandler( props.url );
      } }>
         <IonThumbnail className="icon" slot="start" >
            <img alt={ props.title } src={ props.icon } />
         </IonThumbnail>
         <IonLabel>
            <h3>
               { props.title }
            </h3>
         </IonLabel>
      </IonItem>
   );
};

export default Resource;
