// #region packages
import { ToastContainer, toast, ToastPosition } from "react-toastify";
// #endregion
// #region styles
import "react-toastify/dist/ReactToastify.css";
// #endregion

export interface ToasterOptions {
   timeout?: number;
   onClose?: (() => void) | (() => Promise<any>);
   position?: ToastPosition;
   closeOnClick?: boolean;
   pauseOnHover?: boolean;
   pauseOnFocusLoss?: boolean;
   draggable?: boolean;
   draggablePercent?: number;
   theme?: string;
}

export const popError = ( message: string, options?: ToasterOptions ) => {
   toast.error( message, {
      position: options?.position ?? "top-right",
      autoClose: options?.timeout ?? 15000,
      closeOnClick: options?.closeOnClick ?? true,
      pauseOnHover: options?.pauseOnHover ?? true,
      pauseOnFocusLoss: options?.pauseOnFocusLoss ?? true,
      draggable: options?.draggable ?? true,
      draggablePercent: options?.draggablePercent ?? 40,
      theme: options?.theme ?? "colored",
      onClose: options?.onClose
   } as Object );
};

export const popSuccess = ( message: string, options?: ToasterOptions ) => {
   toast.success( message, {
      position: options?.position ?? "top-right",
      autoClose: options?.timeout ?? 15000,
      closeOnClick: options?.closeOnClick ?? true,
      pauseOnHover: options?.pauseOnHover ?? true,
      pauseOnFocusLoss: options?.pauseOnFocusLoss ?? true,
      draggable: options?.draggable ?? true,
      draggablePercent: options?.draggablePercent ?? 40,
      theme: options?.theme ?? "colored",
      onClose: options?.onClose
   } as Object );
};

export const popWarning = ( message: string, options?: ToasterOptions ) => {
   toast.warn( message, {
      position: options?.position ?? "top-right",
      autoClose: options?.timeout ?? 15000,
      closeOnClick: options?.closeOnClick ?? true,
      pauseOnHover: options?.pauseOnHover ?? true,
      pauseOnFocusLoss: options?.pauseOnFocusLoss ?? true,
      draggable: options?.draggable ?? true,
      draggablePercent: options?.draggablePercent ?? 40,
      theme: options?.theme ?? "colored",
      onClose: options?.onClose
   } as Object );
};

export const popMessage = ( message: string, options?: ToasterOptions ) => {
   toast.info( message, {
      position: options?.position ?? "top-right",
      autoClose: options?.timeout ?? 15000,
      closeOnClick: options?.closeOnClick ?? true,
      pauseOnHover: options?.pauseOnHover ?? true,
      pauseOnFocusLoss: options?.pauseOnFocusLoss ?? true,
      draggable: options?.draggable ?? true,
      draggablePercent: options?.draggablePercent ?? 40,
      theme: options?.theme ?? "colored",
      onClose: options?.onClose
   } as Object );
};

export const showError = ( message: string, options?: ToasterOptions ) => {
   toast.error( message, {
      position: options?.position ?? "top-right",
      autoClose: options?.timeout ?? false,
      closeOnClick: options?.closeOnClick ?? true,
      pauseOnHover: options?.pauseOnHover ?? true,
      pauseOnFocusLoss: options?.pauseOnFocusLoss ?? true,
      draggable: options?.draggable ?? true,
      draggablePercent: options?.draggablePercent ?? 40,
      theme: options?.theme ?? "colored",
      onClose: options?.onClose
   } as Object );
};

export const showSuccess = ( message: string, options?: ToasterOptions ) => {
   toast.success( message, {
      position: options?.position ?? "top-right",
      autoClose: options?.timeout ?? false,
      closeOnClick: options?.closeOnClick ?? true,
      pauseOnHover: options?.pauseOnHover ?? true,
      pauseOnFocusLoss: options?.pauseOnFocusLoss ?? true,
      draggable: options?.draggable ?? true,
      draggablePercent: options?.draggablePercent ?? 40,
      theme: options?.theme ?? "colored",
      onClose: options?.onClose
   } as Object );
};

export const showWarning = ( message: string, options?: ToasterOptions ) => {
   toast.warn( message, {
      position: options?.position ?? "top-right",
      autoClose: options?.timeout ?? false,
      closeOnClick: options?.closeOnClick ?? true,
      pauseOnHover: options?.pauseOnHover ?? true,
      pauseOnFocusLoss: options?.pauseOnFocusLoss ?? true,
      draggable: options?.draggable ?? true,
      draggablePercent: options?.draggablePercent ?? 40,
      theme: options?.theme ?? "colored",
      onClose: options?.onClose
   } as Object );
};

export const showMessage = ( message: string, options?: ToasterOptions ) => {
   toast.info( message, {
      position: options?.position ?? "top-right",
      autoClose: options?.timeout ?? false,
      closeOnClick: options?.closeOnClick ?? true,
      pauseOnHover: options?.pauseOnHover ?? true,
      pauseOnFocusLoss: options?.pauseOnFocusLoss ?? true,
      draggable: options?.draggable ?? true,
      draggablePercent: options?.draggablePercent ?? 40,
      theme: options?.theme ?? "colored",
      onClose: options?.onClose
   } as Object );
};

const Toaster = () => ( <ToastContainer /> );

export default Toaster;
