// #region packages
import { IonCard, IonItem, IonLabel } from "@ionic/react";
// #endregion

interface WelcomeBannerProps {

}

const WelcomeBanner = ( props: WelcomeBannerProps ) => {
   return (
      <IonCard className="banner-text">
         <IonItem className="welcome-banner">
            <img
               alt="logo"
               src="/images/iris-transparent.png"
               className="welcome-image-right"
            />
            <IonLabel className="ion-text-wrap">
               Welcome to MyApprisen
            </IonLabel>
            <img
               alt="iris"
               src="/images/iris-transparent.png"
               className="welcome-image"
               style={ { visibility: "hidden" } }
            />
         </IonItem>
      </IonCard>
   );
};

export default WelcomeBanner;
