// #region packages
import { IonLabel } from "@ionic/react";
// #endregion

interface ProgressTrackerItemProps {
   name: string;
   progressLabel?: string | null;
   justify?: "left" | "right" | "center";
}

const ProgressTrackerItem = ( props: ProgressTrackerItemProps ) => {
   const align = props.justify ?? "left";
   return (
      <IonLabel className="ion-text-wrap">
         <h3 style={ { textAlign: align } }>{ props.name }</h3>
         <div style={ { textAlign: align } } >
            { props.progressLabel }
         </div>
      </IonLabel>
   );
};

export default ProgressTrackerItem;
