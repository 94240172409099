import { LogLevel } from "@azure/msal-browser";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
   names: {
      signUpSignIn: process.env.REACT_APP_POLICY_SIGNUPSIGNIN,
      resetPassword: process.env.REACT_APP_POLICY_RESET_PASSWORD
   },
   authorities: {
      signUpSignIn: {
         authority: process.env.REACT_APP_LOGIN_AUTHORITY
      },
      resetPassword: {
         authority: process.env.REACT_APP_RESET_PASSWORD_AUTHORITY
      }
   },
   authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN
};

export const b2cEndpoints = {
   logout: process.env.REACT_APP_LOGOUT_URL
};

/**
 * Configuration object to be passed to MSAL Instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
   auth: {
      clientId: process.env.REACT_APP_AUTH_CLIENTID as string, // This is the ONLY mandatory field that you need to supply.
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [
         b2cPolicies.authorities.signUpSignIn.authority as string,
         b2cPolicies.authorities.resetPassword.authority as string
      ],
      redirectUri: `${process.env.REACT_APP_WEBAPP_BASE_URL}Login`,
      postLogoutRedirectUri: `${process.env.REACT_APP_WEBAPP_BASE_URL}Login`
   },
   cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
   },
   system: {
      loggerOptions: {
         loggerCallback: (level: any, message: any, containsPii: any) => {
            if (containsPii) {
               console.info("[INFO] *** Contains Pii");
            }
            switch (level) {
               case LogLevel.Error:
                  console.error("[ERROR] " + message);
                  return;
               case LogLevel.Info:
                  console.info("[INFO]  " + message);
                  return;
               case LogLevel.Verbose:
                  console.debug("[DEBUG] " + message);
                  return;
               case LogLevel.Warning:
                  console.warn("[WARN]  " + message);
                  return;
               default:
                  return;
            }
         }
      }
   }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const stdScopes = [
   `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_API_NAME}/access_as_user`
];

export const loginRequest = {
   scopes: stdScopes
};

export const protectedResources = {
   getClientData: {
      endpoint: `${process.env.REACT_APP_SERVICE_BASE_URL}MyApprisen/GetClientData`,
      scopes: stdScopes
   },
   validateBankAccount: {
      endpoint: `${process.env.REACT_APP_SERVICE_BASE_URL}MyApprisen/IsBankAccountValid`,
      scopes: stdScopes
   },
   sendEmail: {
      endpoint: `${process.env.REACT_APP_SERVICE_BASE_URL}MyApprisen/SendEmail`,
      scopes: stdScopes
   },
   createWebPayment: {
      endpoint: `${process.env.REACT_APP_SERVICE_BASE_URL}MyApprisen/CreateWebPayment`,
      scopes: stdScopes
   },
   deleteUser: {
      endpoint: `${process.env.REACT_APP_SERVICE_BASE_URL}MyApprisen/DeleteUser`,
      scopes: stdScopes
   }

};

