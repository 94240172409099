/* eslint no-useless-concat: "off" */
import { useState, useRef } from "react";
// #region packages
import {
   IonButton,
   IonCard,
   IonCardContent,
   IonCol,
   IonContent,
   IonDatetime,
   IonDatetimeButton,
   IonGrid,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonModal,
   IonPage,
   IonRadio,
   IonRadioGroup,
   IonRow,
   IonSelect,
   IonSelectOption,
   useIonRouter
} from "@ionic/react";
import axios from "axios";
// #endregion
// #region components
import AppFooter from "../components/AppFooter";
import OpenPage from "../components/OpenPage";
import SubpageHeader from "../components/SubpageHeader";
import Toaster, { popError, popSuccess } from "../components/Toaster";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region interfaces
import { GenericResponse } from "../interfaces/GenericResponse";
import { User } from "../interfaces/User";
// #endregion
// #region utilities
import { StdErrorMsg } from "../utilities/Constants";
import { addDays } from "../utilities/Formatters";
import { debugLog } from "../utilities/debugLog";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
// #region Azure B2C
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-common";
import { protectedResources } from "../authConfig";
// #endregion

enum FrequencyType {
   Single,
   AllFuture
}

interface ChangeAutopayProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const ChangeAutopay: React.FC<ChangeAutopayProps> = (props) => {
   const user = props.user;

   useIris();
   useFeedbackButton();

   const history = useIonRouter();
   const { instance } = useMsal();
   const [successfulSubmit, setSuccessfulSubmit] = useState<boolean>(false);

   const now = new Date();

   let _firstDate = now; // update at some point to the next pull date, per the following:
   /*
    * https://apprisen.atlassian.net/browse/MYAP-177?focusedCommentId=23097
    * with the calendar you are putting on the page for �what pull do you want to change�  I have confirmed - just default that to today�s date.
    * We aren�t currently getting the actual pull date and using the due date is misleading.  with the go live day coming up and the need to
    * push to all platforms and test, just defaulting to today�s date will be the best.  
    */
   const firstPaymentDate = useRef<Date>(_firstDate);
   const lastPaymentDate = useRef<Date>(addDays(365));

   const nextDayDisplay = now;

   const autopayDate = useRef<Date>(new Date(_firstDate));
   const currentAutopayDate = useRef<Date>(new Date(_firstDate));
   const dayOfMonth = useRef<number>(nextDayDisplay.getDate());
   const [frequency, setFrequency] = useState<FrequencyType>(FrequencyType.Single);

   const sendEmailCall = async (accessToken: string) => {
      const mailbody =
         `<pre>At ${new Date(Date.now()).toLocaleTimeString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZoneName: "short"
         })}, the following autopay change request was made via MyApprisen:</p>` + "<br/>" +
         "Client name:                " + `${user?.FirstName ?? ""} ${user?.LastName ?? ""}` + "<br/>" +
         "Client number:              " + `${user?.ClientNumber ?? ""}` + "<br/>" +
         "Case number:                " + `${user?.DmpCaseId ?? ""}` + "<br/>" +
         "Current withdrawal date:    " + `${currentAutopayDate.current.toLocaleDateString()}` + "<br/>" +
         "Type of change:             " + `${frequency === FrequencyType.Single
            ? "Change next pull date"
            : "Change all future dates"}` + "<br/>" +
         `${frequency === FrequencyType.Single
            ? "Next pull date requested:  "
            : "New day of month requested:"} ` +
         `${frequency === FrequencyType.Single
            ? autopayDate.current.toLocaleDateString()
            : dayOfMonth.current.toString()}` + "<br/>" +
         "</pre>";

      try {
         const url = protectedResources.sendEmail.endpoint;

         debugLog("*** Attempting to contact " + url);

         const smtpResponse = await axios.post<GenericResponse>(
            url, {
            recipients: ["info@apprisen.com"],
            subject: "Apprisen AutoPay Request",
            body: mailbody,
            cache: false
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         });

         if (smtpResponse.data.IsSuccess) {
            popSuccess("Message sent");
            setSuccessfulSubmit(true);
         } else {
            popError(StdErrorMsg("Error sending message."));
         }
      } catch (error) {
         if ((error as any).code === "ERR_NETWORK") {
            popError("Error contacting server. Please check your network connection");
         } else {
            popError(StdErrorMsg("Error sending message."));
         }
      }
   };

   const sendEmail = async () => {
      try {
         const account = instance.getActiveAccount() as AccountInfo | undefined;
         const accessTokenRequest = {
            scopes: protectedResources.sendEmail.scopes,
            account: account
         };

         const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
         const accessToken = accessTokenResponse.accessToken;
         await sendEmailCall(accessToken);
      } catch (error) {
         debugLog((error as any).toString());
         popError(StdErrorMsg("Error sending message."));
      }

   };

   const changeAutopayDateValue = (e: any) => {
      autopayDate.current =
         e.target.value?.toString()
            ? new Date(e.target.value?.toString())
            : firstPaymentDate.current;
   };

   const changeCurrentAutopayDateValue = (e: any) => {
      currentAutopayDate.current =
         e.target.value?.toString()
            ? new Date(e.target.value?.toString())
            : firstPaymentDate.current;
   };

   return (
      <IonPage>
         <SubpageHeader title="Change AutoPay" parentPage="/Overview" />

         <IonContent id="changeAutopay">
            <IonGrid>
               <IonRow>

                  <IonCol size="12" sizeLg="6" offsetLg="3">
                     <IonCard>
                        {successfulSubmit ? (
                           <IonList className="ion-no-padding">

                              <IonListHeader
                                 className="white ion-text-center ion-padding-end">
                                 <IonLabel>
                                    <h2>Request Submitted</h2>
                                 </IonLabel>
                              </IonListHeader>

                              <IonCard>
                                 <IonCardContent>
                                    <p>
                                       Thank you for contacting us regarding your Apprisen Auto Pay.
                                       Your request will be reviewed by one of our agents within the next 24 hours.
                                       If we were successfully able to make your change, you will receive a confirmation that the change is complete.
                                       If there are any issues or questions with your request, one of our agents will contact you.
                                    </p>
                                    <p style={{ marginTop: "1em" }}>
                                       Please note: If this request is submitted after hours, on a weekend or a holiday; we will process it the next business day.
                                    </p>
                                    <IonButton
                                       onClick={(e) => {
                                          e.preventDefault();
                                          history.push("/Overview");
                                       }}
                                       style={{ marginTop: "2em" }}
                                       expand="block">
                                       Back to Overview
                                    </IonButton>

                                 </IonCardContent>
                              </IonCard>
                           </IonList>
                        ) : (
                           <IonList className="ion-no-padding">

                              <IonListHeader
                                 className="white ion-text-center ion-padding-end">
                                 <IonLabel>
                                    <h2>New Withdrawal Date</h2>
                                 </IonLabel>
                              </IonListHeader>

                              <IonCard>
                                 <IonCardContent>
                                    <p>
                                       Making an AutoPay change is easy! You can use this form to request most AutoPay changes.
                                       Before continuing, check out the exceptions below and give us a call <OpenPage url="tel:800-355-2227">(800.355.2227)</OpenPage> if one of
                                       these applies to you:
                                    </p>
                                    <ul style={{ marginTop: "1em" }}>
                                       <li>If you are requesting to change your withdrawal scheduled for today and it is after 3pm ET</li>
                                       <li>If today is Friday after 3pm ET and you are requesting to change a withdrawal that is scheduled this weekend</li>
                                       <li>If you choose a new pull date after the 21st of the month, your payment may not post until the following month</li>
                                    </ul>
                                    <p style={{ marginTop: "1em" }}>
                                       Note: If request is submitted after hours, on a weekend or a holiday; we will process it the next business day.
                                    </p>
                                    <p style={{ marginTop: "1em" }}>
                                       <i>Creditor Rights</i>
                                    </p>
                                    <p style={{ marginTop: "1em" }}>
                                       A couple reminders we're required to give you before we start changing things: If creditors do not receive the full
                                       agreed upon payment every 30 days or, for some creditors, by their established due date each month, they have the right
                                       to remove the accounts from the program, reinstate late fees and/or over limit fees, increase the APR, and resume any
                                       type of collection activity. Please let us know if you notice any of these changes reflected on your statements.
                                    </p>
                                 </IonCardContent>
                              </IonCard>

                              <IonItem lines="none" style={{ paddingBottom: "0", marginBottom: "0" }}>
                                 <p>
                                    Which withdrawal date(s) would you like to change?<br />
                                    <p style={{ fontSize: "12px", marginLeft: "1em", marginBottom: "0", marginTop: ".25em" }}>
                                       Note: Your due date on the Overview page is not your Apprisen Autopay withdrawal date.
                                       Please check your Apprisen paperwork for the date that you selected to have your funds withdrawn.
                                    </p>
                                 </p>
                              </IonItem>

                              <IonItem lines="none" style={{ marginTop: "0", minHeight: "0" }}>
                                 <IonGrid>
                                    <IonRow>
                                       <IonCol size="12">
                                          <IonRadioGroup value={frequency} onIonChange={(e) => setFrequency(e.target.value)}>

                                             <IonItem lines="none" style={{ marginBottom: "0" }}>
                                                <IonLabel style={{ marginBottom: "0" }}>Current month</IonLabel>
                                                <IonRadio slot="start" value={FrequencyType.Single} style={{ marginBottom: "0" }} />
                                             </IonItem>
                                             <IonItem lines="none" style={{ marginTop: "0" }}>
                                                <IonLabel style={{ marginTop: "0" }}>All future months</IonLabel>
                                                <IonRadio slot="start" value={FrequencyType.AllFuture} style={{ marginTop: "0" }} />
                                             </IonItem>

                                          </IonRadioGroup>
                                       </IonCol>

                                       <IonCol size="12">
                                          <IonItem lines="none" >
                                             <IonLabel position="stacked">
                                                What is your current withdrawal date? (If you are unsure, skip this field.)
                                             </IonLabel>
                                             <div className="input calendar-input" >
                                                <IonDatetimeButton datetime="calendar1" color="light" />
                                                <IonModal keepContentsMounted={true} backdropDismiss={false}>
                                                   <IonDatetime id="calendar1"
                                                      presentation="date"
                                                      showDefaultButtons={true}
                                                      onIonChange={changeCurrentAutopayDateValue}
                                                      value={currentAutopayDate.current.toISOString()}
                                                      min={firstPaymentDate.current.toISOString()}
                                                      max={lastPaymentDate.current.toISOString()} />
                                                </IonModal>
                                             </div>
                                          </IonItem>
                                       </IonCol>

                                       <IonCol size="12">
                                          {frequency === FrequencyType.Single && (
                                             <IonItem lines="none" style={{ marginTop: "1em" }}>
                                                <IonLabel position="stacked">
                                                   New withdrawal date
                                                </IonLabel>
                                                <div className="input calendar-input" >
                                                   <IonDatetimeButton datetime="calendar2" color="light" />
                                                   <IonModal keepContentsMounted={true} backdropDismiss={false}>
                                                      <IonDatetime id="calendar2"
                                                         presentation="date"
                                                         showDefaultButtons={true}
                                                         onIonChange={changeAutopayDateValue}
                                                         value={autopayDate.current.toISOString()}
                                                         min={firstPaymentDate.current.toISOString()}
                                                         max={lastPaymentDate.current.toISOString()} />
                                                   </IonModal>
                                                </div>
                                             </IonItem>
                                          )}

                                          {frequency === FrequencyType.AllFuture && (
                                             <IonItem lines="none" style={{ marginTop: "1em" }}>
                                                <IonLabel position="stacked" style={{ marginBottom: ".5em" }}>
                                                   New Withdrawal Day
                                                </IonLabel>
                                                <IonSelect
                                                   value={dayOfMonth.current}
                                                   style={{ width: "11em" }}
                                                   onIonChange={(e) => dayOfMonth.current = +e.target.value}
                                                >
                                                   {
                                                      Array.from({ length: 28 }, (_, i) => i + 1)
                                                         .map(opt => {
                                                            return (
                                                               <IonSelectOption key={opt} value={opt}>
                                                                  {opt.toString()}
                                                               </IonSelectOption>
                                                            );
                                                         })
                                                   }
                                                </IonSelect>
                                                <IonLabel position="stacked" style={{ marginTop: "-.25em", paddingBottom: ".25em" }}>
                                                   Due to short calendar months, permanent changes cannot extend past the 28th.
                                                </IonLabel>
                                             </IonItem>
                                          )}
                                       </IonCol>
                                    </IonRow>
                                 </IonGrid>
                              </IonItem>

                              <IonItem>
                                 <IonButton
                                    className="full-button"
                                    expand="full"
                                    onClick={() => sendEmail()}>
                                    Send Request
                                 </IonButton>
                              </IonItem>

                           </IonList>
                        )}
                     </IonCard>
                  </IonCol>
               </IonRow>
            </IonGrid>
            <p className="withIris">&nbsp;</p>
         </IonContent>
         <Toaster />
         <AppFooter />
         <AppIdleTimer logout={props.logout} />
      </IonPage >
   );
};

export default ChangeAutopay;
