// #region packages
import {
   IonCard,
   IonCol,
   IonGrid,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
   IonProgressBar,
   IonRow,
} from "@ionic/react";
// #endregion
// #region components
import ProgressTrackerItem from "./ProgressTrackerItem";
// #endregion
// #region interfaces
import { User, CaseDebt } from "../interfaces/User";
// #endregion
// #region utilities
import { currency } from "../utilities/Formatters";
// #endregion

interface ProgressTrackerCardProps {
   user?: User | null;
   specificDebt?: CaseDebt;
}

const ProgressTrackerCard = ( props: ProgressTrackerCardProps ) => {
   const user = props.user!;
   const originalBalance = props.specificDebt
      ? (props.specificDebt.OriginalBalance ?? 0)
      : (user?.TotalOriginalBalance ?? 0);
   const monthlyPayment = props.specificDebt
      ? (props.specificDebt.MonthlyPayment ?? 0)
      : (user?.CurrentMonthlyPayment ?? 0);
   const currentBalance = props.specificDebt
      ? (props.specificDebt.CurrentBalance ?? 0)
      : (user?.EstimatedBalance ?? 0);

   return (
      <IonCard >
         <IonList className="ion-no-padding" lines="none">
            <IonListHeader className="white" >
               <IonLabel>
                  <h2>Progress</h2>
               </IonLabel>
            </IonListHeader>
            <IonItem style={ { padding: "10px 10px 0 10px" } }>
               <IonProgressBar value={ props.specificDebt ? CaseDebt.Progress( props.specificDebt ) : (user?.CurrentProgress ?? 0) } />
            </IonItem>
            <IonItem>
               <IonGrid>
                  <IonRow>
                     <IonCol className="progress-tracker-label" size="4" >
                        <ProgressTrackerItem name="Original Balance" justify="left" progressLabel={ currency( originalBalance ) } />
                     </IonCol>
                     <IonCol className="progress-tracker-label center-aligned" size="4" >
                        <ProgressTrackerItem name="Current Monthly Payment" justify="center" progressLabel={ currency( monthlyPayment ) } />
                     </IonCol>
                     <IonCol className="progress-tracker-label right-aligned" size="4" >
                        <ProgressTrackerItem name="Current Balance" justify="right" progressLabel={ currency( currentBalance ) } />
                     </IonCol>
                  </IonRow>
               </IonGrid>
            </IonItem>
         </IonList>
      </IonCard>
   );
};
export default ProgressTrackerCard;
