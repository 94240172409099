import React from "react";
// #region packages
import {
   IonCard,
   IonCol,
   IonContent,
   IonGrid,
   IonLabel,
   IonList,
   IonListHeader,
   IonPage,
   IonRow
} from "@ionic/react";
// #endregion
// #region components
import AppFooter from "../components/AppFooter";
import ExpandableList from "../components/ExpandableList";
import ListDisplayItem from "../components/ListDisplayItem";
import SubpageHeader from "../components/SubpageHeader";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region interfaces
import { CaseFeeDetail, User } from "../interfaces/User";
// #endregion
// #region utilities
import { date, currency } from "../utilities/Formatters";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
interface FeesProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const Fees: React.FC<FeesProps> = (props) => {
   const user = props.user;

   useIris();
   useFeedbackButton();

   const fees = user?.CaseFeeDetails ?? ([] as CaseFeeDetail[]);

   const getFeeItem = (fee: CaseFeeDetail, index?: number) => {
      return (
         <ListDisplayItem key={index} name={date(fee.PostedDate) ?? ""} value1={fee.Title ?? ""} value2={currency(fee.Amount) ?? ""} />
      );
   };

   return (
      <IonPage>
         <SubpageHeader title="Fee History" parentPage="/AccountOverview" />

         <IonContent id="fees">
            <IonGrid>
               <IonRow>
                  <IonCol size="12" sizeLg="8" offsetLg="2">
                     <IonCard>
                        <IonList className="ion-no-padding">
                           <IonListHeader className="white ion-padding-end">
                              <IonLabel>
                                 <h2>DMP Fees Paid</h2>
                              </IonLabel>
                           </IonListHeader>
                           <ExpandableList
                              data={fees}
                              onItemDisplay={getFeeItem}
                              scroll={true}
                              visibleRows={10}
                              collapsible={false}
                           />
                        </IonList>
                     </IonCard>
                  </IonCol>
               </IonRow>
            </IonGrid>
         </IonContent>
         <AppFooter />
         <AppIdleTimer logout={props.logout} />
      </IonPage>
   );

};

export default Fees;
