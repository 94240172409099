// #region packages
import { IonItem, IonLabel, IonThumbnail, } from "@ionic/react";
// #endregion

interface OverviewItemProps {
   name: string;
   iconImage: string;
   value: JSX.Element;
}

const OverviewItem = ( props: OverviewItemProps ) => {

   return (
      <IonItem>
         <IonThumbnail className="icon" slot="end" >
            <img alt={ props.name } src={ props.iconImage } />
         </IonThumbnail>
         <IonLabel>
            <h3>{ props.name }</h3>
            <p>{ props.value }</p>
         </IonLabel>
      </IonItem>
   );
};

export default OverviewItem;
