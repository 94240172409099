// #region packages
import {
   IonButton,
   IonCard,
   IonCol,
   IonGrid,
   IonItem,
   IonLabel,
   IonProgressBar,
   IonRow,
   useIonRouter,
} from "@ionic/react";

import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
// #endregion
// #region components
import ExpandableList from "./ExpandableList";
// #endregion
// #region interfaces
import { CaseDebt } from "../interfaces/User";
// #endregion
// #region utilities
import { currency } from "../utilities/Formatters";
// #endregion

interface LenderListProps {
   debts: CaseDebt[];
}

const LenderList = ( props: LenderListProps ) => {
   const history = useIonRouter();

   const handleUploadStatementClick = () => {
      InAppBrowser.create(
         "https://clientportal.apprisen.com",
         "_system",
         "location=yes"
      );
   };

   const getDebtItem = ( caseDebt: CaseDebt ) =>
   (
      <IonItem className="lender-flex" key={ caseDebt.DebtId }>
         <IonGrid>
            <IonRow>
               <IonCol size="12">
                  <IonLabel className="ion-text-wrap" >
                     <h3 style={ { width: "100%" } }>
                        { caseDebt.CreditorName } { caseDebt.AccountNumber
                           ? ` (${ caseDebt.AccountNumber.length >= 4
                              ? caseDebt.AccountNumber.substring( caseDebt.AccountNumber.length - 4 )
                              : caseDebt.AccountNumber })`
                           : "" }
                     </h3>
                     <p>{ currency( caseDebt.CurrentBalance ?? 0 ) }</p>
                  </IonLabel>
               </IonCol>
            </IonRow>
            <IonRow>
               <IonCol size="10">
                  <div className="lender-progress-holder">
                     <IonProgressBar value={ CaseDebt.Progress( caseDebt ) } />
                  </div>
               </IonCol>
               <IonCol size="2">
                  <IonButton
                     onClick={ ( e ) => {
                        e.preventDefault();
                        history.push( `/LenderOverview?debt=${ caseDebt.DebtId }` );
                     } }
                     fill="clear" className="lender-button">
                     Info
                  </IonButton>
               </IonCol>
            </IonRow>
         </IonGrid>
      </IonItem >
   );


   return (
      <IonCard >
         <ExpandableList
            data={ props.debts }
            title="Lenders"
            scroll={ false }
            onItemDisplay={ getDebtItem } />

         <IonItem className="full-button" style={ { marginTop: "0" } } lines="none">
            <IonButton className="full-button" expand="full" onClick={ ( e ) => {
               e.preventDefault();
               handleUploadStatementClick();
            } }>
               Upload Statement
            </IonButton>
         </IonItem>

      </IonCard>
   );
};

export default LenderList;
