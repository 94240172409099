export const CONTACT_US_EMAILS = [
   "clientsuccess@apprisen.com"
];

export const BRAND_COLORS = [
   "#008752",
   "#FAA634",
   "#007FB2",
   "#439539",
   "#008E7F",
   "#BEC0C2",
   "#0096D6",
   "#6CB33F"
   //"#717073",
];

//export const LINKED_APP_NAME = "ChaseFFG";
export const StdErrorMsg = (error: string) => `${error} Please try again, contact customer service at 800-355-2227 or chat with IRIS.`;
export const createAccountEmailTemplate = `Thank you for creating your MyApprisen account. Please click the link below to complete the setup.\n\nIf additional emails have been requested, the most recent email must be used to complete your account setup.\n\n${ process.env.REACT_APP_WEBAPP_BASE_URL }/Verify/[[membershipId]]`;

// eslint-disable-next-line
export const ref2822EmailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
