import { createRoot } from "react-dom/client";
import { IonReactRouter } from "@ionic/react-router";
import { ErrorBoundary } from "react-error-boundary";
import { Redirect } from "react-router-dom";

import App from "./App";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { debugLog } from "./utilities/debugLog";
//import NavigationAuthenticationClient from "./components/NavigationAuthenticationClient";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
//msalInstance.setNavigationClient(new NavigationAuthenticationClient(msalInstance));

msalInstance.initialize().then(() => {
   debugLog("MSAL instance initializing");

   // Default to using the first account if no account is active on page load
   if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      debugLog(`Setting active account from first account: ${msalInstance.getAllAccounts()[0]}`);
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
   }

   // Optional - This will update account state if a user signs in from another tab or window
   msalInstance.enableAccountStorageEvents();

   // Optional - This will catch any redirect errors
   msalInstance.handleRedirectPromise().catch(error => {
      debugLog(`Error in handleRedirectPromise: ${error}`);
   });

   // Optional - This will log in all currently signed in accounts and scopes to the console
   msalInstance.getAllAccounts().forEach(account => {
      const username = account.username ?? "";
      debugLog(`Account: ${username}`);
      const currentAccount = msalInstance.getActiveAccount();
      if (currentAccount) {
         debugLog(`Active account: ${currentAccount.username}`);
      }
   });

   const rootElement = document.getElementById("root") as HTMLElement;
   const root = createRoot(rootElement);

   // Note that StrictMode got changed in how it behaves and now causes a page redraw that really
   // messes with react-hook-forms. Disable it until that all gets sorted.

   root.render(
      <ErrorBoundary fallback={<Redirect to="/login" />}>
         <MsalProvider instance={msalInstance}>
            <IonReactRouter>
               <App />
            </IonReactRouter>
         </MsalProvider>
      </ErrorBoundary>
   );
});

// Pass a function to log performance results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
