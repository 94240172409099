// #region packages
import {
   IonButtons,
   IonHeader,
   IonMenuButton,
   IonThumbnail,
   IonTitle,
   IonToolbar,
   useIonRouter,
} from "@ionic/react";
// #endregion


interface HeaderProps {
   title?: string;
}

const Header = ( props: HeaderProps ) => {
   const history = useIonRouter();
   document.title = props.title ? ("MyApprisen - " + props.title) : "MyApprisen";

   return (
      <IonHeader>
         <IonToolbar>
            <IonThumbnail
               className="toolbar-logo"
               slot="start"
               style={ { cursor: "pointer" } }
               onClick={ ( e ) => {
                  e.preventDefault();
                  history.push( "/Overview" );
               } }>
               <img alt="apprisen-logo" src="/images/apprisen-logo.png" />
            </IonThumbnail>
            <IonTitle
               style={ { cursor: "pointer" } }
               onClick={ ( e ) => {
                  e.preventDefault();
                  history.push( "/Overview" );
               } }>

               { props.title ?? "MyApprisen" }</IonTitle>
            <IonButtons slot="end">
               <IonMenuButton></IonMenuButton>
            </IonButtons>
         </IonToolbar>
      </IonHeader>
   );
}

export default Header;
