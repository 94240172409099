import { useEffect } from "react";

const useFeedbackButton = () => {
   useEffect(() => {
      const script = document.createElement("script");

      script.src =
         "https://apprisen.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=9e8a1c29";
      script.type = "text/javascript";
      script.async = true;

      document.body.appendChild(script);

      const style = document.createElement("style");
      style.innerText = ".atlwdg-trigger.atlwdg-SUBTLE { left: 0; bottom: 1.1rem; border: 1px solid #ccc; border-bottom: none; border-right: none; background-color: #f5f5f5; color: #444 !important; font-size: 11px; padding: 6px; -webkit-box-shadow: -1px -1px 2px rgb(0,0,0); box-shadow: -1px -1px 2px rgb(0,0,0); border-radius: 2px 0 0 0; position: absolute; } @media(max-width: 553px) { .atlwdg-trigger.atlwdg-SUBTLE { bottom: 1.8rem; }}";
      document.body.appendChild(style);

      //   return () => {
      //      document.body.removeChild(script);
      //   }
   });
};

export default useFeedbackButton;
