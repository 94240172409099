// #region packages
import { Chart, ChartData, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// #endregion
// #region interfaces
import { CaseDebt, DebtType } from "../interfaces/User";
// #endregion
// #region utilities
import { date } from "../utilities/Formatters";
import { BRAND_COLORS } from "../utilities/Constants";
// #endregion

Chart.register(ArcElement, Tooltip, Legend);

interface DebtGraphProps {
   items: CaseDebt[];
}

const DebtGraph = (props: DebtGraphProps) => {

   const getDebts = (): CaseDebt[] => {
      let result: CaseDebt[];
      const allDebts = props.items;
      const colors = BRAND_COLORS;

      if (allDebts.length <= colors.length) {
         result = allDebts;
      }
      else {
         result = allDebts.slice(0, colors.length - 1);

         let otherDebts = allDebts.slice(result.length);
         let otherOriginalBalance = 0;
         let otherCurrentBalance = 0;
         for (let i = 0; i < otherDebts.length; i++) {
            otherOriginalBalance += otherDebts[i].OriginalBalance!;
            otherCurrentBalance += otherDebts[i].CurrentBalance!;
         }

         const other = new CaseDebt();

         other.AccountNumber = "-";
         other.Apr = 0;
         other.CreditorName = "Other";
         other.CurrentBalance = otherCurrentBalance;
         other.DebtId = "-111";
         other.DebtType = DebtType.PayingOn;
         other.LastCreditorPaymentDate = date(new Date());
         other.OriginalBalance = otherOriginalBalance;
         result.push(other);
      }

      return result;
   };

   const graphedDebts = getDebts();

   const data: ChartData<"doughnut", number[], string> = {
      labels: graphedDebts.map((x: CaseDebt) => x.CreditorName!),
      datasets: [
         {
            data: graphedDebts.map((x: CaseDebt) => x.CurrentBalance!),
            backgroundColor: BRAND_COLORS,
            hoverBackgroundColor: BRAND_COLORS
         }
      ]
   };

   //const options: any = {
   //   legend: { display: true, position: "top" },
   //   maintainAspectRatio: true
   //};

   return (<Doughnut data={data} updateMode="resize" />);
};

export default DebtGraph;