// #region packages
import {
   IonBackButton,
   IonButtons,
   IonHeader,
   IonTitle,
   IonToolbar,
} from "@ionic/react";
// #endregion

interface SubpageHeaderProps {
   title?: string;
   parentPage?: string;
}

const SubpageHeader = ( props: SubpageHeaderProps ) => {
   document.title = props.title ? ("MyApprisen - " + props.title) : "MyApprisen";

   return (
      <IonHeader>
         <IonToolbar>
            <IonButtons slot="start">
               <IonBackButton defaultHref={ props.parentPage ?? "/overview" } />
            </IonButtons>
            <IonTitle>{ props.title ?? "Apprisen" }</IonTitle>
         </IonToolbar>
      </IonHeader>
   );
}

export default SubpageHeader;
