import { useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { debugLog } from "../utilities/debugLog";

const AppUrlListener: React.FC<any> = () => {
   useEffect(() => {
      App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
         debugLog(`App opened with URL: ${event.url}`);
         let slug: string | undefined | null = null;

         if (event.url.startsWith("myapprisen://")) {
            slug = event.url.split("myapprisen:/").pop();
         } else if (event.url.startsWith(process.env.REACT_APP_WEBAPP_BASE_URL!)) {
            slug = `/${ event.url.split(process.env.REACT_APP_WEBAPP_BASE_URL!).pop() ?? "" }`;
         }

         if (slug && slug.length) {
               debugLog(`AppUrlListener: Navigating to ${slug}`);
               window.location.assign(slug);
            }
         
      });
   }, []);

   return null;
};

export default AppUrlListener;
