import { useRef, Fragment } from "react";
// #region packages
import { Link } from "react-router-dom";
import {
   IonButton,
   IonButtons,
   IonCard,
   IonCol,
   IonContent,
   IonGrid,
   IonHeader,
   IonModal,
   IonPage,
   IonRow,
   IonTitle,
   IonToolbar
} from "@ionic/react";
import axios from "axios";
// #endregion
// #region components
import Header from "../components/Header";
import Menu from "../components/Menu";
import AppFooter from "../components/AppFooter";
import ListDisplayItem from "../components/ListDisplayItem";
import WelcomeBanner from "../components/WelcomeBanner";
import Toaster, { popError, popSuccess } from "../components/Toaster";
import AppIdleTimer from "../components/AppIdleTimer";
// #endregion
// #region interfaces
import { GenericResponse } from "../interfaces/GenericResponse";
import { User } from "../interfaces/User";
// #endregion
// #region utilities
import { StdErrorMsg } from "../utilities/Constants";
import { debugLog } from "../utilities/debugLog";
// #endregion
// #region hooks
import useIris from "../hooks/useIris";
import useFeedbackButton from "../hooks/useFeedbackButton";
// #endregion
// #region Azure B2C
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../authConfig";
import { AccountInfo } from "@azure/msal-browser";
// #endregion

interface DeleteUserRequest {
   ClientId?: string;
   IdentityId?: string;
};

interface ProfileProps {
   logout: () => void;
   login: () => void;
   user: User;
}

// ReSharper disable once UnusedParameter
const Profile: React.FC<ProfileProps> = (props) => {
   const user = props.user;

   useIris();
   useFeedbackButton();

   const { instance } = useMsal();

   const modal = useRef<HTMLIonModalElement>(null);

   const deleteUserCall = async (accessToken: string) => {
      const url = protectedResources.deleteUser.endpoint;
      debugLog(`*** Attempting to contact ${url}`);

      try {
         const data: DeleteUserRequest = { ClientId: user?.ClientId, IdentityId: user?.IdentityId };
         const apiResponse = await axios.post<GenericResponse>(
            url, {
            cache: false,
            ...data
         }, {
            headers: {
               Authorization: `Bearer ${accessToken}`
            }
         });

         if (apiResponse.data.IsSuccess) {
            popSuccess("Your account been deleted", { onClose: () => props.logout() });
         } else {
            popError(StdErrorMsg("Error requesting account deletion."));
         }
      } catch (error) {
         if ((error as any).code === "ERR_NETWORK") {
            popError("Error contacting server. Please check your network connection");
         } else {
            popError(StdErrorMsg("Error requesting account deletion."));
         }
      } finally {
         modal.current?.dismiss();
      };

   };
   const deleteUser = async () => {
      const account = instance.getActiveAccount() as AccountInfo | undefined;
      const accessTokenRequest = {
         scopes: protectedResources.deleteUser.scopes,
         account: account
      };

      instance.acquireTokenSilent(accessTokenRequest)
         .then((accessTokenResponse) => {
            const accessToken = accessTokenResponse.accessToken;
            deleteUserCall(accessToken);
         })
         .catch((error) => {
            debugLog(error);
            popError(StdErrorMsg("Error requesting account deletion."));
         });
   };

   return (
      <Fragment>
         <Menu contentId="profile" />
         <IonPage>
            <Header title="Profile" />
            <IonContent id="profile">
               <IonGrid>
                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <WelcomeBanner />
                     </IonCol>
                  </IonRow>

                  <IonRow>
                     <IonCol size="12" sizeLg="8" offsetLg="2">
                        <IonCard>
                           <div>
                              <ListDisplayItem name="Name" value={`${user?.FirstName ?? ""} ${user?.LastName ?? ""}`} />
                              <ListDisplayItem name="Email" value={user?.Email ?? ""} />
                              <ListDisplayItem name="Phone Number" value={user?.CellPhone ?? ""} />
                              <ListDisplayItem name="Address" value={user?.StreetAddress ?? ""} />
                              <ListDisplayItem name="City" value={user?.City ?? ""} />
                              <ListDisplayItem name="State" value={user?.State ?? ""} />
                              <ListDisplayItem name="Client Number" value={user?.ClientNumber?.toString() ?? ""} />
                              <ListDisplayItem name="Case Number" value={user?.DmpCaseId?.toString() ?? ""} />
                           </div>
                        </IonCard>
                     </IonCol>
                  </IonRow>
                  <IonRow>
                     <IonCol size="12" sizeLg="4" offsetLg="4">
                        <IonButton className="full-button" expand="full" id="deleteAccountBtn">
                           Delete My Account
                        </IonButton>
                     </IonCol>
                  </IonRow>
               </IonGrid>
               <IonModal ref={modal} trigger="deleteAccountBtn" backdropDismiss={false}>
                  <IonHeader>
                     <IonToolbar>
                        <IonButtons slot="start">
                           <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
                        </IonButtons>
                        <IonTitle style={{ textAlign: "center" }}>Important - Please Read</IonTitle>
                        <IonButtons slot="end">
                           <IonButton strong={true} onClick={deleteUser}>
                              Confirm
                           </IonButton>
                        </IonButtons>
                     </IonToolbar>
                  </IonHeader>
                  <IonCard className="ion-padding">
                     <p>
                        Deleting your account will remove your access to the app and any other application that you access with your user account.
                        While your account will be deleted, Apprisen is required by law to maintain certain personal data as described in our
                        Privacy Policy. The account deletion process does not impact data and records that Apprisen is required by law to
                        maintain, such as transaction records associated with your Debt Management Plan.
                     </p>
                     <p>
                        Please note that this account deletion does not terminate your Debt Management Program.
                        If you intend to close your Debt Management Program, <Link to="/Contact" onClick={() => modal.current?.dismiss()}>please contact us!</Link>
                     </p>
                  </IonCard>
               </IonModal>
               <p className="withIris">&nbsp;</p>
            </IonContent>
            <Toaster />
            <AppFooter />
            <AppIdleTimer logout={props.logout} />
         </IonPage >
      </Fragment>
   );
};

export default Profile;
