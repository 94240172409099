import { Fragment, useState } from "react";
// #region packages
import { Link } from "react-router-dom";
import { arrowForward, chevronDownCircleOutline, chevronUpCircleOutline } from "ionicons/icons";
import {
   IonFabButton,
   IonIcon,
   IonItem,
   IonLabel,
   IonList,
   IonListHeader,
} from "@ionic/react";
// #endregion

interface ExpandableListProps {
   title?: string;
   data: any[];
   onItemDisplay: (obj: any, index?: number) => JSX.Element;
   scroll?: boolean;
   visibleRows?: number;
   collapsible?: boolean;
   detailLink?: string;
}

const ExpandableList = (props: ExpandableListProps) => {
   const dataLength = props.data?.length ?? 0;
   const visibleItemCount = Math.min(dataLength, props.visibleRows ?? 5);
   const scroll = props.scroll === undefined ? true : props.scroll;
   const collapsible = dataLength === 0 || props.collapsible === undefined ? false : props.collapsible;
   const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

   return (
      <IonList className="ion-no-padding">
         {
            props.title && (
               <IonListHeader className="white" >
                  <IonLabel>
                     <h2>
                        {(collapsible && isCollapsed) ? (
                           <IonIcon icon={chevronDownCircleOutline} onClick={()=>setIsCollapsed(false)} style={{ height: "1.1em", width: "1.1em", marginBottom: "-0.15em", color: "white" }} />
                        ) : (<Fragment />)}
                        {(collapsible && !isCollapsed) ? (
                           <IonIcon icon={chevronUpCircleOutline} onClick={()=>setIsCollapsed(true)} style={{ height: "1.1em", width: "1.1em", marginBottom: "-0.15em", color: "white" }} />
                        ) : (<Fragment />)}
                        {props.title}
                     </h2>
                  </IonLabel>
                  {props.detailLink && (
                     <Link to={props.detailLink!} >
                        <IonFabButton className="fab-button" color="light">
                           <IonIcon className="arrow-color" icon={arrowForward} />
                        </IonFabButton>
                     </Link>
                  )}
               </IonListHeader>
            )
         }
         {!isCollapsed && dataLength > 0 && scroll &&
            (
               <div style={{ height: visibleItemCount * 4 + .5 + "em", overflowY: "auto" }}>
                  {props.data.map((item, index) => props.onItemDisplay(item, index))}
               </div>
            )
         }
         {!isCollapsed && dataLength > 0 && !scroll &&
            (
               <div>
                  {props.data.map((item, index) => props.onItemDisplay(item, index))}
               </div>
            )
         }
         {!isCollapsed && dataLength === 0 && props.title &&
            (
               <IonItem>
                  <IonLabel>
                     <h3 className="full-center">None</h3>
                  </IonLabel>
               </IonItem>
            )
         }
      </IonList>
   );
};

export default ExpandableList;
